import React, { useState, useEffect, useContext } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import {PaymentContext} from './CardDetails';

function CheckoutForm() {
  const paymentRequest = useContext(PaymentContext);

  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);      

  const handleStripePay = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // payment completion page
        return_url: `${process.env.REACT_APP_FRONTEND_BASE_URL}/payment-status?mid=${paymentRequest.memberID}&gid=${paymentRequest.gcid}&tp=${paymentRequest.termPlan}`,
        receipt_email: email
      },
    });

    // If there is an immediate error when confirming the payment. 
    // Otherwise, customer will be redirected to `return_url`.    
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <div>
      <input id='email' type='text' placeholder="Email" className='payment-input' value={email} onChange={(e) => setEmail(e.target.value)} />

      <PaymentElement id="payment-element" />

      <button id="submit" className="btn btn-block btn-primary" disabled={isLoading || !stripe || !elements} onClick={handleStripePay}>
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please wait...</div>
          ) : (
            "Confirm Pay"
          )}
        </span>
      </button>
      {message &&
        <div id="payment-message">
          {message}
        </div>
      }
    </div>
  );
}

export default CheckoutForm;