import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MenuMember from './MenuMember';
import DOMPurify from "dompurify";

function EmergencyDetails() {
    const location = useLocation();
    const { memInfo } = location.state;
    const { emrInfo } = location.state;    
        
    const [disability, setDisability] = useState('No');
    const [resOrder, setResOrder] = useState('No');
    const [hlConditions, setHLConditions] = useState('');
    const [memName, setMemName] = useState();
    const [memFullAddress, setMemFullAddress] = useState();
    const [homeType, setHomeType] = useState();
    const [locationPets, setLocationPets] = useState();
    const [othersContact, setOthersContact] = useState();
    const [othersContactHtml, setOthersContactHtml] = useState(); 
    const [emrType, setEmrType] = useState('');
    const [memberAge, setMemberAge] = useState(0);   

    useEffect(() => {
        setEmrType(emrInfo.emergencyType);

        if (memInfo.dateOfBirth) {
            let dtDOB = new Date(memInfo.dateOfBirth);
            let dtEmr = new Date();
            if (dtEmr > dtDOB) {            
                setMemberAge(Math.floor((dtEmr.getTime() - dtDOB.getTime()) / (1000 * 60 * 60 * 24 * 365)));            
            }            
        }

        if (memInfo.disabilities) {
            setDisability('Yes');
        }        
        if (memInfo.restrainingOrder) {
            setResOrder('Yes');
        }
        if (memInfo.healthConditions) {
            let subStr = memInfo.healthConditions.substring(0, memInfo.healthConditions.length - 1);            
            let newStr = subStr.replace(new RegExp(';', 'g'), ', ')     // global flag g, to replace all digits
            setHLConditions(newStr);
        }

        setMemName(memInfo.firstName + ' ' + memInfo.lastName);
        setMemFullAddress(memInfo.address + ', ' + memInfo.city + ', ' + memInfo.state + ', ' + memInfo.zip);
        
        let hType = memInfo.homeType;
        if (memInfo.homeType === 'Apartment' || memInfo.homeType === 'Townhouse') {
            if (memInfo.multiLevel) {
                hType += ', Multi-level, Floor - ' + memInfo.levelNumber + ', Fastest way to access emergency location - ' + memInfo.fastestAccess;
            } else {
                hType += ', Fastest way to access emergency location - ' + memInfo.fastestAccess;
            }           
        } else if (memInfo.homeType === 'High Rise') {
            hType += ', Floor - ' + memInfo.levelNumber + ', Room # - ' + memInfo.roomNumber + ', Fastest way to access emergency location - ' + memInfo.fastestAccess;
        } else {
            hType += ', Fastest way to access emergency location - ' + memInfo.fastestAccess;
        }          
        setHomeType(hType);

        let locPets = '';
        memInfo.pets.forEach((item, index) => {
            if (item.petNature) {
                locPets += item.petType + '(Aggressive)';
            } else {
                if (item.petType.toLowerCase() === 'other')
                    locPets += item.petType + '(' + item.notes + ')';                 
                else
                    locPets += item.petType;
            }

            if (index < memInfo.pets.length - 1) {
                locPets += ', ';
            }                
        })                
        setLocationPets(locPets);

        let contactsHtml = '';
        memInfo.emergencyContacts.forEach((item) => {
            let contactInfo = '';
            if (item.name) {
                contactInfo += item.name;                
            }
            if (item.phone) {
                if (contactInfo !== '')
                    contactInfo +=  ', ' + formatPhoneNumber(item.phone);
                else
                    contactInfo +=  formatPhoneNumber(item.phone);
            }
            if (item.address) {
                if (contactInfo !== '')
                    contactInfo +=  ', ' + item.address;
                else
                    contactInfo +=  item.address;                
            }
            
            if (contactInfo?.trim()) {
                contactsHtml += "<span style='width:100%; padding-top: 0; display: inline-block;'>" + contactInfo + '</span>';
                setOthersContact(contactInfo);
            }                          
        })
        let contacts = <div style={{width: '100%', padding: '0'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contactsHtml) }} />; 
        setOthersContactHtml(contacts);                        
    }, []);

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        const phoneNumber = value.replace(/[^\d]/g, '');

        if (phoneNumber.length < 4) return phoneNumber;

        if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h5 style={{ color: "#fff " }}>Detail Information</h5>
                                    <table id="info" width="100%">
                                        <tbody>
                                            <tr>
                                                <td>Emergency:</td>
                                                <td><b style={{ color: '#f19f1f' }}>{emrType.toUpperCase()}</b></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" style={{ padding: '0' }}><img src="/assets/img/map.jpg" style={{ padding: '0', width: '100%' }} class="img-responsive" /></td>
                                            </tr>                                            
                                            {emrType.toLowerCase() === 'fire' &&
                                                <>
                                                    <tr>
                                                        <td>Specific location of the fire:</td>
                                                        <td>{emrInfo.specificLocationOfFire}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Building type:</td>
                                                        <td>{emrInfo.storyType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fire started in:</td>
                                                        <td>{emrInfo.fireStarted}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Number of people currently at this location:</td>
                                                        <td>{emrInfo.peopleAtLocation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Have you removed yourself/others to safety from this emergency:</td>
                                                        <td>{emrInfo.removedToSafety}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Injuries associated with this fire:</td>
                                                        <td>{emrInfo.fireInjuries}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Building type:</td>
                                                        <td>{memInfo.buildingType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Construction type:</td>
                                                        <td>{memInfo.constructionType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Number of stories:</td>
                                                        <td>{memInfo.numberOfStories}</td>
                                                    </tr>
                                                </>
                                            }
                                            {emrType.toLowerCase() === 'medical' &&
                                                <>
                                                    <tr>
                                                        <td>Nature of emergency:</td>
                                                        <td>{emrInfo.natureOfEmergencyMedical}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>State of person injured:</td>
                                                        <td>{emrInfo.injuredPersonState}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Age of injured person:</td>
                                                        <td>{emrInfo.injuredPersonAge}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Disabilities:</td>
                                                        <td>{disability}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Previous health conditions:</td>
                                                        <td>{hlConditions}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Care taker mobile:</td>
                                                        <td>{memInfo.careTakerPhone}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Care taker name:</td>
                                                        <td>{memInfo.careTakerName}</td>
                                                    </tr>
                                                </>
                                            }
                                            {emrType.toLowerCase() === 'police' &&
                                                <>
                                                    <tr>
                                                        <td>Nature of emergency:</td>
                                                        <td>{emrInfo.natureOfEmergencyPolice}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Medical aid is required:</td>
                                                        <td>{emrInfo.requiredMedicalAid}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Weapon/weapons used:</td>
                                                        <td>{emrInfo.weapons}</td>
                                                    </tr>

                                                    <tr>
                                                        <td>Is there a restraining order:</td>
                                                        <td>{resOrder}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Restraining order name:</td>
                                                        <td>{memInfo.restrainingOrderName}</td>
                                                    </tr>
                                                </>
                                            }
                                            <tr>
                                                <td>Name:</td>
                                                <td>{memName}</td>
                                            </tr>
                                            <tr>
                                                <td>Address:</td>
                                                <td>{memFullAddress}</td>
                                            </tr>
                                            <tr>
                                                <td>Mobile:</td>
                                                <td>{memInfo.phone}</td>
                                            </tr>
                                            <tr>
                                                <td>Email:</td>
                                                <td>{memInfo.email}</td>
                                            </tr>
                                            <tr>
                                                <td>Age:</td>
                                                <td>{memberAge}</td>
                                            </tr>                                            
                                            <tr>
                                                <td>Home type:</td>
                                                <td>{homeType}</td>
                                            </tr>                                            
                                            <tr>
                                                <td>Others contact:</td>
                                                <td>{othersContactHtml}</td>
                                            </tr>
                                            <tr>
                                                <td>Pets at location:</td>
                                                <td>{locationPets}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmergencyDetails;