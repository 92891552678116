import React, { useState, useEffect, useRef } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import axios from "axios";
import { connect } from 'react-redux';
import MenuAdmin from './MenuAdmin';
import { useReactToPrint } from 'react-to-print';

function PrintGiftCards({ history, adminInfo }) {
    const adminId = parseInt(adminInfo.map((item) => item.aId));
    const location = useLocation();
    const [printGiftCardData, setPrintGiftCardData] = useState([{
        gcid: 0,
        code: null,
        agentName: null,
        amount: 0,
        message: null
    }]);

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/admin/generate-gift-cards');
        } else {
            // location.state is startGCID
            if (adminId > 0 && location.state > 0) {
                axios
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/GiftCards/GetGiftGenerated/${adminId}/${location.state}`)
                    .then((response) => {
                        setPrintGiftCardData(response.data);                        
                    });
            }
        }
    }, []);

    // For Print
    const printPageStyle = `
        @page {            
            margin: 9mm; 
        }
          
        @media print {
            html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
            }
        }
    `;

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'GiftCards',
        pageStyle: printPageStyle,
        removeAfterPrint: true
    });
    //    

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Print Gift Cards</h3>
                                </div>
                                <div ref={componentRef}>
                                    {printGiftCardData.map((data, index) => (
                                        <div key={index} class="col-md-4 form-group" style={{ float: 'left', width: '367px' }}>
                                            <div className="giftcards">
                                                <div class="gift-left-col">
                                                    My<br />Emergency<br />Network
                                                </div>
                                                <div class="gift-right-col">
                                                    <p class="gift-amount">{'$' + data.amount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</p>
                                                    <p class="gift-code-label">Gift Card Code:</p>
                                                    <p class="gift-code">{data.code}</p>
                                                </div>
                                                <p class="gift-agent">{data.agentName}</p>
                                                <p class="gift-message">{data.message}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div class="col-md-12 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handlePrint}>Print</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ adminLogin: { adminInfo } }) => ({
    adminInfo
})

export default connect(mapStateToProps)(withRouter(PrintGiftCards));