import React from 'react';
import { useField, ErrorMessage } from 'formik';

export const MyTextInput = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    // console.log(field);
    // console.log(meta);
    return (
        <>
            <label htmlFor={field.id || field.name}>{label}</label>
            <input className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`} 
                {...field} {...props} autoComplete='off' />
            {/* {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null} */}
            <ErrorMessage name={field.name} className='error' />
        </>
    );
};

export const MyCheckbox = ({ children, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' }); 
    return ( 
      <div> 
        <label className="checkbox-input"> 
          <input type="checkbox" {...field} {...props} /> 
          {children} 
        </label> 
        {meta.touched && meta.error ? ( 
          <div className="error">{meta.error}</div> 
        ) : null} 
      </div> 
    ); 
};
 
export const MySelect = ({ label, ...props }) => { 
    const [field, meta] = useField(props); 
    return ( 
        <div> 
        <label htmlFor={field.id || field.name}>{label}</label> 
        <select {...field} {...props} /> 
        {meta.touched && meta.error ? ( 
            <div className="error">{meta.error}</div> 
        ) : null} 
        </div> 
    ); 
};