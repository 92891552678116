import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import InputMask from 'react-input-mask';
import axios from "axios";
import CMSMenu from './CMSMenu';
import AddDeleteTableRows from './AddDeleteTableRows';

function UpdateProfileNew({ history }) {
    const location = useLocation();
    const [messageDiv, setMessageDiv] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [allStates, setAllStates] = useState([]);
    const [date, setDate] = useState(new Date());
    const [confirmDate, setConfirmDate] = useState(new Date());    
    const [memberData, setMemberData] = useState({
        memberID: 0,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        phone: null,
        address: null,        
        city: null,
        state: null,
        zip: null,
        latitude: 0,
        longitude: 0,
        homeType: null,        
        multiLevel: false,
        levelNumber: null,
        fastestAccess: null,
        roomNumber: null,
        bestAccessToEnter: null,
        referredBy: null,
        pets: [],
        emergencyContacts: []        
    });
    const [petInfo, setPetInfo] = useState([
        { Id: 1, petMember: false, mPetID: 0, memberID: 0, petType: 'Bird', petNature: false, notes: null },
        { Id: 2, petMember: false, mPetID: 0, memberID: 0, petType: 'Cat', petNature: false, notes: null },
        { Id: 3, petMember: false, mPetID: 0, memberID: 0, petType: 'Dog', petNature: false, notes: null },
        { Id: 4, petMember: false, mPetID: 0, memberID: 0, petType: 'Ferret', petNature: false, notes: null },
        { Id: 5, petMember: false, mPetID: 0, memberID: 0, petType: 'Pocket Pet', petNature: false, notes: null },
        { Id: 6, petMember: false, mPetID: 0, memberID: 0, petType: 'Snake', petNature: false, notes: null },        
        { Id: 7, petMember: false, mPetID: 0, memberID: 0, petType: 'Other', petNature: false, notes: null }
    ]);
    const [currentEContacts, setCurrentEContacts] = useState([
        { mecid: 0, memberID: 0, name: '', phone: '', email: '', relation: '', address: '' }                
    ]);
    const hometype = [
        { Id: 1, Type: 'Apartment' },
        { Id: 2, Type: 'Condo' },
        { Id: 3, Type: 'High Rise' },
        { Id: 4, Type: 'House' },
        { Id: 5, Type: 'Mobile Home' },
        { Id: 6, Type: 'Office' },
        { Id: 7, Type: 'RV' },
        { Id: 8, Type: 'School' },
        { Id: 9, Type: 'Shopping Center' },
        { Id: 10, Type: 'Townhouse' }
    ];
    // const fastestaccess = [
    //     { Id: 1, Type: 'Inside' },
    //     { Id: 2, Type: 'Outside' },
    //     { Id: 3, Type: 'Stairs/Elevator' }
    // ];
    // const bestaccess = [
    //     { Id: 1, Type: 'Front Door' },
    //     { Id: 2, Type: 'Side Entrance Left' },
    //     { Id: 3, Type: 'Side Entrance Right' }
    // ];
    const hometypefields = { text: 'Type', value: 'Type' };
    // const fastestaccessfields = { text: 'Type', value: 'Type' };
    // const bestaccessfields = { text: 'Type', value: 'Type' };
    const statefields = { text: 'stateName', value: 'stateCode' };

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-registration');
        } else {
            memberData.email = location.state.email;
            memberData.password = location.state.password;
        }

        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/States/GetState`)
            .then((response) => {
                setAllStates(response.data);
            });
    }, []);

    let eContacts = [];
    const handleEmergencyContacts = (emergContacts) => {
        eContacts = emergContacts;
    }
    const handleChange = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value });
    };
    const handleChangeUnmask = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value.replace(/[^0-9]+/g, '') });
    };
    const handleChangeBool = (e, property) => {        
        setMemberData({ ...memberData, [property]: e.target.value.toLowerCase() === 'true' });
    };
    const handleProfileNext = () => {
        try {
            memberData.dateOfBirth = fixTimezoneOffset(date);

            if (memberData.firstName && memberData.lastName && memberData.phone && memberData.dateOfBirth && confirmDate) {
                setMessageDiv(false);
                if (date.getTime() === confirmDate.getTime()) {
                    memberData.emergencyContacts = eContacts.filter((data) => data.name.trim() !== '' && data.phone.trim() !== '').slice(0, 10); 
                    
                    petInfo.forEach((data) => {                 
                        if (data.petMember) {                    
                            memberData.pets.push(data);                                                                             
                        }
                    })
                    
                    if (memberData.homeType === 'Apartment' || memberData.homeType === 'Townhouse') {                
                        // memberData.bestAccessToEnter = '';
                        memberData.roomNumber = '';
                        if (!memberData.multiLevel) {
                            memberData.levelNumber = '';
                        }
                    } else if (memberData.homeType === 'High Rise') {
                        memberData.multiLevel = false;
                    } else {
                        memberData.multiLevel = false;
                        memberData.levelNumber = '';
                        memberData.roomNumber = '';
                        // memberData.bestAccessToEnter = '';
                    }            

                    history.push({
                        pathname: '/fire-registration',
                        state: memberData
                    });
                } else {
                    alert("Date of Birth doesn't match.");
                }
            } else {
                setErrorMessage('Enter First Name, Last Name, Mobile, Date of Birth & Confirm DOB.');
                setMessageDiv(true);
            }            
        }
        catch (ex) {
            alert('Error...');
        }        
    }
    
    const fixTimezoneOffset = (dt) => {
        try {
            if (!dt) return null;
        
            return new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000));
            // getTime() returns the number of milliseconds since January 1, 1970 00:00:00
            // getTimezoneOffset() returns the difference between UTC time and local time, in minutes
        }
        catch {
            return null;
        }        
    };

    return (
        <div>
            <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
                <div class="main-container">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6 text-right">
                                <CMSMenu />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 text-center">
                            </div>
                            <div class="col-md-7">
                                {messageDiv &&
                                    <div className='errorMsg'>
                                        <i class="fa fa-warning warn"></i>
                                        <span style={{display:'inline-block', lineHeight:'27px'}}>{errorMessage}</span>                                    
                                    </div>
                                }                                
                                <div class="form-container" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '20px' }}>
                                    <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right", paddingRight: "15px" }}></i></Link>
                                    <div class="form-group">
                                        <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Member Registration</h3>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label>First Name</label>
                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.firstName} onChange={(e) => handleChange(e, "firstName")} />
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label>Last Name</label>
                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.lastName} onChange={(e) => handleChange(e, "lastName")} />
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label>Mobile</label>
                                        <InputMask className='textmasking' mask='(999) 999-9999' value={memberData.phone} onChange={(e) => handleChangeUnmask(e, "phone")} />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>Date of Birth</label>
                                        <DatePicker value={date} onChange={setDate} maxDate={new Date()} format='MM/dd/yyyy' closeCalendar={true} disabled={false} autoFocus={false}/>                                        
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>Confirm DOB (cannot be edited in the future)</label>
                                        <DatePicker value={confirmDate} onChange={setConfirmDate} maxDate={new Date()} format='MM/dd/yyyy' closeCalendar={true} disabled={false} autoFocus={false}/>                                        
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <label>Address</label>
                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.address} onChange={(e) => handleChange(e, "address")} />
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label>City</label>
                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.city} onChange={(e) => handleChange(e, "city")} />
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label>State</label>
                                        <DropDownListComponent
                                            id="ddlState"
                                            cssClass="e-outline input-border"
                                            dataSource={allStates}
                                            fields={statefields}
                                            value={memberData.state}
                                            onChange={(e) => handleChange(e, "state")}
                                            placeholder="Select"
                                            popupHeight="250px"
                                        />
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <label>Zip</label>
                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.zip} onChange={(e) => handleChange(e, "zip")} />
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <label>Building Type</label>
                                        <DropDownListComponent
                                            cssClass="e-outline input-border"
                                            dataSource={hometype}
                                            fields={hometypefields}
                                            value={memberData.homeType}
                                            onChange={(e) => handleChange(e, "homeType")}
                                            placeholder="Choose One"
                                        />
                                    </div>
                                    <div class="col-md-12 form-group">                                        
                                        {(memberData.homeType === 'Apartment' || memberData.homeType === 'Townhouse') &&
                                            <div class="boxborder">
                                                <div class="col-md-12 form-group">
                                                    Multi-level&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <RadioButtonComponent label='Yes' name='multilevel' value='true' onChange={(e) => handleChangeBool(e, "multiLevel")} checked={memberData.multiLevel} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <RadioButtonComponent label='No' name='multilevel' value='false' onChange={(e) => handleChangeBool(e, "multiLevel")} checked={!memberData.multiLevel} />
                                                </div>
                                                {memberData.multiLevel &&
                                                    <div class="col-md-12 form-group">
                                                        <label>Which level/floor</label>
                                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.levelNumber} onChange={(e) => handleChange(e, "levelNumber")} />
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {memberData.homeType === 'High Rise' &&
                                            <div class="boxborder">
                                                <div class="col-md-6 form-group">
                                                    <label>Which level/floor</label>
                                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.levelNumber} onChange={(e) => handleChange(e, "levelNumber")} />
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <label>Room #</label>
                                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.roomNumber} onChange={(e) => handleChange(e, "roomNumber")} />
                                                </div>
                                            </div>
                                        }
                                        {/* <div class="col-md-12 form-group">
                                            <label>Fastest way to access emergency location</label>
                                            <DropDownListComponent
                                                cssClass="e-outline input-border"
                                                dataSource={fastestaccess}
                                                fields={fastestaccessfields}
                                                value={memberData.fastestAccess}
                                                onChange={(e) => handleChange(e, "fastestAccess")}
                                                placeholder="Choose One"
                                            />
                                        </div> */}                                        
                                    </div>                                    
                                    {/* {memberData.homeType === 'Townhouse' &&
                                        <div class="col-md-12 form-group">
                                            <div class="boxborder">
                                                <div class="col-md-12 form-group">
                                                    <label>Best access to enter</label>
                                                    <DropDownListComponent
                                                        cssClass="e-outline input-border"
                                                        dataSource={bestaccess}
                                                        fields={bestaccessfields}
                                                        value={memberData.bestAccessToEnter}
                                                        onChange={(e) => handleChange(e, "bestAccessToEnter")}
                                                        placeholder="Choose One"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    } */}
                                    <div class="col-md-12 form-group">
                                        <label>Emergency Contacts</label>
                                        <div class="boxborder">
                                            <AddDeleteTableRows getEmergencyContacts={handleEmergencyContacts} currentEmergencyContacts={currentEContacts} />
                                        </div>                                        
                                    </div>
                                    <div class="col-40">
                                        <label>Pets at location</label>                                        
                                    </div>                                   
                                    <div class="col-60">
                                        <label>Aggressive </label>                                        
                                    </div>
                                    <div class="col-md-12 form-group">
                                        {petInfo.map((data, index) => (
                                            <div key={index} class='row'>
                                                <div class="col-40 form-group-pet">
                                                    <CheckBoxComponent checked={data.petMember} label={data.petType}
                                                        onChange={(e) => {
                                                            data.petMember = e.target.checked;
                                                            setPetInfo([...petInfo]);
                                                        }} />
                                                </div>                                                
                                                <div class="col-60 form-group-pet">
                                                    {data.petType.toLowerCase() !== 'other' &&
                                                        <CheckBoxComponent checked={data.petNature}
                                                            onChange={(e) => {
                                                                data.petNature = e.target.checked;
                                                                setPetInfo([...petInfo]);
                                                            }} />
                                                    }
                                                    {data.petType.toLowerCase() === 'other' && data.petMember &&                                                        
                                                        <div class="col-md-12 form-group" style={{ marginTop: '5px', paddingLeft: '0' }}>                                                                                
                                                            <TextBoxComponent cssClass="e-outline input-border" value={data.notes} 
                                                                onChange={(e) => {
                                                                    data.notes = e.target.value;
                                                                    setPetInfo([...petInfo]);
                                                                }} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}                                        
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <label>Referred By</label>
                                        <TextBoxComponent cssClass="e-outline input-border" value={memberData.referredBy} onChange={(e) => handleChange(e, "referredBy")} />
                                    </div>                                    
                                    <div class="col-md-12 form-group">
                                        <button className="btn btn-block btn-primary" onClick={handleProfileNext}>Continue</button>
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(UpdateProfileNew);