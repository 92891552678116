import React, { useState, useEffect } from 'react';
import { Fetch } from "../services/dataservice";
// // import { useLocation } from 'react-router-dom';
// // import queryString from 'query-string';

function OptOut() {
    // // const { search } = useLocation();    
    // // const { emer, opt } = queryString.parse(search);     // issues due to special characters in querystring    
    
    // const { emer, opt } = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
    const { emer, opt } = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = e.split('=')[1], r), {});    // querystring value will be decoded by WebAPI 
    
    const [verifyMessage, setVerifyMessage] = useState('');
    const [optoutMail, setOptoutMail] = useState({
        memberID: emer,
        email: opt
    });    
    
    useEffect(() => {
        try {
            (async () => {
                // // const encodeEncryptMemberId = encodeURIComponent(optoutMail.memberID);   // Without this step, the server will process the slashes / as a new sub route and return a 404. Also create issue for plus + in query string 
                // // const encodeEncryptEmail = encodeURIComponent(optoutMail.email);
                // // if (encodeEncryptMemberId && encodeEncryptEmail) {

                if (optoutMail.memberID && optoutMail.email) {
                    let result = await Fetch(`/Member/UpdateEmergencyContact`, "put", optoutMail);                
                    if (result > 0) {                                                  
                        setVerifyMessage('Successfully opt out from contact list.');
                    } else {
                        setVerifyMessage('Already opt out.');
                    }
                }                
            })();

            return () => {
                // this now gets called when the component unmounts            
            };
        }
        catch (ex) {
            alert('Error...');
        }
    }, []);

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            <div className="form-container">
                                <div class="form-group text-center">
                                    <br />
                                    <i class="fa fa-check" aria-hidden="true" style={{ fontSize: '40px', color: 'green' }}></i>
                                    <br /><br />
                                    <a href="https://myemergencynetwork.com/" style={{ textDecoration: "underline", fontSize: "16px" }}>My Emergency Network</a>
                                    <br /><br />
                                    <h6 style={{ color: "#fff", width: "100%", textAlign: "center", textTransform: 'none', fontWeight: 'normal' }}>
                                        {verifyMessage}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OptOut;