import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import axios from "axios";
import { Fetch } from "../../services/dataservice";
import { connect } from 'react-redux';
import MenuAdmin from './MenuAdmin';

const AddUpdateGiftCards = ({ history, adminInfo }) => {
    const adminId = parseInt(adminInfo.map((item) => item.aId));
    const location = useLocation();
    const [giftCardData, setGiftCardData] = useState({
        gcid: 0,
        adminID: adminId,
        agentName: null,
        code: null,
        amount: 49.95,
        status: null,
        expiryDate: null,
        message: null
    });
    const giftstatus = [
        { Val: true, Type: 'Active' },
        { Val: false, Type: 'Hold' }
    ];

    const giftstatusfields = { text: 'Type', value: 'Val' };

    const handleChange = (e, property) => {
        setGiftCardData({ ...giftCardData, [property]: e.target.value });
    };
    const handleChangeFloat = (e, property) => {
        setGiftCardData({ ...giftCardData, [property]: parseFloat(e.target.value) });
    };

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/admin/manage-gift-cards');
        } else {
            // location.state is GCID
            if (location.state > 0) {
                axios
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/GiftCards/GetById/` + location.state)
                    .then((response) => {
                        setGiftCardData(response.data);
                    });
            }
        }
    }, []);

    const handleSave = async () => {
        try {
            if (giftCardData.code !== null && giftCardData.code !== '' && giftCardData.amount >= 49.95 && giftCardData.status !== null) {
                let data = [];
                if (location.state > 0) {
                    data = await Fetch(`/GiftCards/Update/`, "put", giftCardData);
                } else {
                    data = await Fetch(`/GiftCards/Add/`, "post", giftCardData);
                }

                if (data.result == 'success') {
                    alert('Gift Card details are saved.');
                    if (location.state > 0) {
                        history.push({
                            pathname: '/admin/manage-gift-cards'
                        });
                    }
                } else {
                    alert('Error in saving gift card.');
                }
            } else {
                alert('Enter Gift card code, amount & status.');
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };
    const handleCancel = () => {
        history.push({
            pathname: '/admin/manage-gift-cards'
        });
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Add/Edit Gift Card</h3>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Agent Name</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={giftCardData.agentName} onChange={(e) => handleChange(e, "agentName")} />
                                </div>
                                <div class="col-md-5 form-group">
                                    <label>Gift Card Code</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={giftCardData.code} onChange={(e) => handleChange(e, "code")} />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Amount</label>
                                    <NumericTextBoxComponent format='c2' min={49.95} cssClass="e-outline input-border"
                                        value={giftCardData.amount} onChange={(e) => handleChangeFloat(e, "amount")} />
                                </div>
                                <div class="col-md-3 form-group">
                                    <label>Status</label>
                                    <DropDownListComponent
                                        cssClass="e-outline input-border"
                                        dataSource={giftstatus}
                                        fields={giftstatusfields}
                                        value={giftCardData.status}
                                        onChange={(e) => handleChange(e, "status")}
                                        placeholder="-- Select --"
                                    />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Message</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={giftCardData.message} onChange={(e) => handleChange(e, "message")} />
                                </div>
                                <div class="col-md-6 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handleSave}>Save</button>
                                </div>
                                <div class="col-md-6 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ adminLogin: { adminInfo } }) => ({
    adminInfo
})

export default connect(mapStateToProps)(withRouter(AddUpdateGiftCards));