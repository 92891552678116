import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MyTextInput, MyCheckbox, MySelect } from './InputField';

const Valid = () => {    
    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;                           
    const phoneRules = /^(?:\d{3}|\(\d{3}\))([-/.])\d{3}\1\d{4}$/;  //with area code, ###-###-####, phone number is not valid
    const validate = Yup.object({
        firstName: Yup.string()
            .max(20, 'Maximum 20 characters or less')            
            .required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .matches(passwordRules, 'At least one uppercase letter, lowercase letter, numeric digit & special character each')                        
            .required('Required'),
        confirmPassword: Yup.string() 
            .oneOf([Yup.ref('password'), null], 'Password must match')
            .required('Required'),
        // oldPassword: Yup.string().min(8),
        // password: Yup.string()
        //      .min(8)
        //      .when('oldPassword', (oldPassword, field) =>
        //       oldPassword ? field.required() : field
        //      ),
        // passwordConfirm: Yup.string().when('password', (password, field) =>
        //      password ? field.required().oneOf([Yup.ref('password')]) : field
        //     )        
        age: Yup.number()
            .positive()
            .integer()
            .required('Required'),
        acceptedTerms: Yup.boolean()            
            .oneOf([true], 'You must accept the terms and conditions')
            .required('Required'),
        jobType: Yup.string()
            .oneOf(
                ['designer', 'development', 'product', 'other'],
                'Invalid Job Type'
            )
            .required('Required'),
    });

    const handleValid = (values) => {
        console.log(values);
        //set value here for react useState variables
    }

    return (       
        <Formik
            initialValues={{
                firstName: '',
                email: '',
                password: '',
                acceptedTerms: false,
                jobType: '',
            }}
            validationSchema={validate}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    // console.log('Form Data', values);
                    setSubmitting(false);
                    handleValid(values);
                }, 400);
            }}
        >                       
            <Form>
                <MyTextInput
                    label="First Name"
                    name="firstName"
                    type="text"
                    placeholder="Enter first name"                        
                />
                <MyTextInput
                    label="Message"
                    name="message"
                    type="textarea" 
                />
                <MyTextInput
                    label="Email Address"
                    name="email"
                    type="email"                        
                />
                <MyTextInput
                    label="Password"
                    name="password"
                    type="password"
                />
                <MySelect label="Job Type" name="jobType">
                    <option value="">Select a job type</option>
                    <option value="designer">Designer</option>
                    <option value="development">Developer</option>
                    <option value="product">Product Manager</option>
                    <option value="other">Other</option>
                </MySelect>
                <MyCheckbox name="acceptedTerms">
                    I accept the terms and conditions
                </MyCheckbox>

                <button type="submit">Submit</button>
            </Form>             
        </Formik>        
    );
}

export default Valid