import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuMember from './MenuMember';
import MembershipSubscription from './MembershipSubscription';

const MemberPayment = ({ history, memberInfo }) => {
    const memberId = parseInt(memberInfo.map((item) => item.mId));
    const [showChild, setShowChild] = useState(false);
    const [memberData, setMemberData] = useState({
        memberID: 0,
        callFrom: 'member'
    });

    useEffect(() => {
        if (memberId > 0) {
            memberData.memberID = memberId;
            setShowChild(true);
        } else {
            alert('An error occurred while processing your request. Please try again.');
            history.push('/member');
        }
    }, []);

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '20px' }}>                                
                                {showChild &&
                                    <MembershipSubscription currentMember={memberData} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps)(withRouter(MemberPayment));