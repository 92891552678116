const initialState = {
    isLogin: false,
    memberInfo: []    
}

export const loginReducer = (state = initialState, action) => {    
    switch(action.type){
        case 'STATUS':
            return { ...state, isLogin:action.payload };
        case 'EXIST':
            return { ...state, memberInfo:action.payload };
        case 'LOGOUT':
            return initialState;  //undefined;
        default:
            return state;
    }
}