import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import InputMask from 'react-input-mask';
import axios from "axios";
import { Fetch } from "../../services/dataservice";
import MenuAdmin from './MenuAdmin';

const AddUpdateCountyEmergencyContact = ({ history }) => {
    const location = useLocation();
    const [allStates, setAllStates] = useState([]);
    const [oldCountyName, setOldCountyName] = useState('');
    const [oldState, setOldState] = useState('');
    const [oldZip, setOldZip] = useState('');
    const [countyEmergencyData, setCountyEmergencyData] = useState({
        cecid: 0,
        county: null,
        phone: null,
        email: null,
        fax: null,
        state: null,
        zip: null
    });

    const statefields = { text: 'stateName', value: 'stateCode' };

    const handleChange = (e, property) => {
        setCountyEmergencyData({ ...countyEmergencyData, [property]: e.target.value });
    };
    const handleChangeUnmask = (e, property) => {
        setCountyEmergencyData({ ...countyEmergencyData, [property]: e.target.value.replace(/[^0-9]+/g, '') });
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/States/GetState`)
            .then((response) => {
                setAllStates(response.data);
            });

        if (location.state === undefined) {
            history.push('/admin/manage-county-emergency-contact');
        } else {
            // location.state is CECID 
            if (location.state > 0) {
                // execute after 0.1 seconds for binding allStates before it
                const timer = setTimeout(() => {
                    axios
                        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/CountyEmergencyContact/GetById/` + location.state)
                        .then((response) => {
                            setCountyEmergencyData(response.data);
                            setOldCountyName(response.data.county);
                            setOldState(response.data.state);
                            setOldZip(response.data.zip);                            
                        });
                }, 100);
                return () => clearTimeout(timer);
            }
        }
    }, []);

    const handleSave = async () => {
        try {
            if (countyEmergencyData.county !== null && countyEmergencyData.county !== '' && countyEmergencyData.phone !== null && countyEmergencyData.phone !== ''
                && countyEmergencyData.state !== null && countyEmergencyData.state !== '' && countyEmergencyData.zip !== null && countyEmergencyData.zip !== '') {
                let data = { result: 'failure' };
                let nonUniqueCounty = { status: false };
                if (location.state > 0) {
                    if (oldCountyName === countyEmergencyData.county.trim() &&
                        oldState === countyEmergencyData.state &&
                        oldZip === countyEmergencyData.zip.trim()) {
                        data = await Fetch(`/CountyEmergencyContact/Update/`, "put", countyEmergencyData);
                        showMessage(data, nonUniqueCounty);
                    }
                    else {
                        axios
                            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/CountyEmergencyContact/GetNonUniqueCountyStatus/${countyEmergencyData.county}/${countyEmergencyData.state}/${countyEmergencyData.zip}`)
                            .then((response) => {
                                nonUniqueCounty = response.data;

                                (async () => {
                                    if (!nonUniqueCounty.status) {
                                        data = await Fetch(`/CountyEmergencyContact/Update/`, "put", countyEmergencyData);
                                        showMessage(data, nonUniqueCounty);
                                    }
                                    else {
                                        showMessage(data, nonUniqueCounty);
                                    }
                                })();

                                return () => {
                                    // this now gets called when the component unmounts            
                                };
                            });
                    }
                } else {
                    data = await Fetch(`/CountyEmergencyContact/Add/`, "post", countyEmergencyData);
                    nonUniqueCounty = { status: true };
                    showMessage(data, nonUniqueCounty);
                    setCountyEmergencyData({
                        cecid: 0,
                        county: null,
                        phone: '',
                        email: null,
                        fax: '',
                        state: null,
                        zip: null
                    });
                }
            } else {
                alert('Enter county name, state, zip & phone number.');
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };

    const showMessage = (data, nonUniqueCounty) => {
        if (data.result == 'success') {
            alert('County emergency contact details are saved.');
            if (location.state > 0) {
                history.push({
                    pathname: '/admin/manage-county-emergency-contact'
                });
            }
        } else {
            if (!nonUniqueCounty.status) {
                alert('Error on saving county details.');
            } else {
                alert('County already exists on the list.');
            }
        }
    }

    const handleCancel = () => {
        history.push({
            pathname: '/admin/manage-county-emergency-contact'
        });
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Add/Edit County Emergency Contact</h3>
                                </div>
                                <div class="col-md-7 form-group">
                                    <label>County Name</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={countyEmergencyData.county} onChange={(e) => handleChange(e, "county")} />
                                </div>
                                <div class="col-md-5 form-group">
                                    <label>State</label>
                                    <DropDownListComponent
                                        cssClass="e-outline input-border"
                                        dataSource={allStates}
                                        fields={statefields}
                                        value={countyEmergencyData.state}
                                        onChange={(e) => handleChange(e, "state")}
                                        placeholder="-- Select --"
                                        popupHeight="250px"
                                    />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Phone</label>
                                    <InputMask className='textmasking' mask='(999) 999-9999' value={countyEmergencyData.phone} onChange={(e) => handleChangeUnmask(e, "phone")} />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Fax</label>
                                    <InputMask className='textmasking' mask='(999) 999-9999' maskChar='' value={countyEmergencyData.fax} onChange={(e) => handleChangeUnmask(e, "fax")} />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Zip</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={countyEmergencyData.zip} onChange={(e) => handleChange(e, "zip")} />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Email</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={countyEmergencyData.email} onChange={(e) => handleChange(e, "email")} />
                                </div>
                                <div class="col-md-6 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handleSave}>Save</button>
                                </div>
                                <div class="col-md-6 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AddUpdateCountyEmergencyContact);