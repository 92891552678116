import React, { useState, useEffect } from 'react';
import { Link, withRouter, } from 'react-router-dom';
import MenuMember from './MenuMember';
import axios from "axios";
import Modal from 'react-modal';
import ModalCancel from 'react-modal';
import { connect } from 'react-redux';
import { reset } from "../redux/action/login.action";
import Cookies from 'js-cookie';

Modal.setAppElement('#root');
ModalCancel.setAppElement('#root');

function Dashboard({ history, clearState, memberInfo }) {
    const memberId = parseInt(memberInfo.map((item) => item.mId));

    const memberStatus = false;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalCancelIsOpen, setModalCancelIsOpen] = useState(false);

    const handleCancel = () => setModalCancelIsOpen(true);
    const handleCancelClose = () => setModalCancelIsOpen(false);
    // const handleShow = () => setModalIsOpen(true);  
    const handleClose = () => setModalIsOpen(false);
    const handleLatter = () => setModalIsOpen(false);
    const handleContinue = () => {
        history.push('/member/update-profile');
    }

    useEffect(() => {
        let modalStatus = Cookies.get('modal_profile');
        if (modalStatus === undefined) {
            setModalIsOpen(true);

            //let d = new Date();            
            //d.setTime(d.getTime() + (24*60*60*1000));    // 24hrs
            let d = 1   // 1 day
            Cookies.set('modal_profile', 'seen', { expires: d });
        }
    }, []);

    const handleConfirm = async () => {
        try {
            axios
                .put(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/UpdateMemberStatus/${memberId}/${memberStatus}`)
                .then((response) => {
                    if (response.data > 0) {
                        clearState();
                        history.push('/member-login');
                    } else {
                        getCancelError();
                    }
                });
        }
        catch (ex) {
            getCancelError();
        }
    }

    const getCancelError = () => {
        setModalCancelIsOpen(false);
        alert('An error occurred. Please try again later.');
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container">
                                <div class="form-group text-center">
                                    <h4 style={{ color: "#fff" }}>Fast, Reliable, Alert System via<br />Emergency Dispatch</h4>
                                    {/* <Link to="/member/emergency-contact"><img src="assets/img/sos-alert.png" alt="" width="250px" /></Link> */}
                                    <img src="assets/img/sos-alert.png" alt="" width="250px" />
                                    {/* <h5 style={{ color: "#fff", textTransform: "none" }}>PRESS/HOLD Text 911 button for 3 seconds to send emergency message to contacts.</h5> */}
                                    <h6 style={{ color: "#F19F1F", textTransform: "none" }}>This feature will work on mobile devices only.</h6>
                                    <br />
                                    <a href="https://myemergencynetwork.com/Non%20Emergency%20Links.shtml" className='red-btn' target="_blank">Non Emergency Links</a>
                                </div>
                                <div className="form-group" style={{ clear: 'both', width: '100%', textAlign: 'center', display: 'inline-block', marginBottom: '10px' }}>
                                    <button className="btnlink" onClick={handleCancel}>Cancel Subscription</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {/* <button className="btn btn-block btn-primary" onClick={handleShow}>Modal Window</button> */}
                <Modal isOpen={modalIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto',
                                height: '300px',
                                fontSize: '20px'
                            }
                        }
                    }
                >
                    <div>
                        <button className="crossbtn" onClick={handleClose} title='Close'>X</button>
                    </div>
                    <div style={{ clear: 'both', margin: '25px 0', minHeight: '130px' }}>
                        <p>Please update your profile.</p>
                    </div>
                    <div>
                        <div style={{ float: 'left', width: '47%', }}><button className="btn btn-block btn-primary" onClick={handleContinue}>Continue</button></div>
                        <div style={{ float: 'right', width: '47%', }}><button className="btn btn-block btn-primary" onClick={handleLatter}>Later</button></div>
                    </div>
                </Modal>
            </div>

            <div>
                <ModalCancel isOpen={modalCancelIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto',
                                height: '300px'
                            }
                        }
                    }
                >
                    <div className="modalcontent">
                        <p style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>Confirm Cancellation</p><br />
                        <p>Do you end your subscription now?</p>
                        <button className="btn btn-block btn-primary right" onClick={handleCancelClose} style={{ width: '47%', marginTop: '30px', float: 'left', padding: '15px 0', fontSize: '17px' }}>Not Now</button>
                        <button className="btn btn-block btn-primary right" onClick={handleConfirm} style={{ width: '47%', marginTop: '30px', float: 'right', padding: '15px 0', fontSize: '17px' }}>Confirm</button>
                    </div>
                </ModalCancel>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    clearState: () => dispatch(reset())
})

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));