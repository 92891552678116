import React, { useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

const DeviceDetect = () => {
    
    useEffect(() => {
        window.location.replace(`${process.env.REACT_APP_FRONTEND_BASE_URL}`);
        // if(isIOS){            
        //     window.location.replace(`${process.env.REACT_APP_IOS_URL}`)
        // } else if (isAndroid) {            
        //     window.location.replace(`${process.env.REACT_APP_ANDROID_URL}`)
        // } else {
        //     window.location.replace(`${process.env.REACT_APP_FRONTEND_BASE_URL}`);
        // }
    }, []);

    return (
        <>
        </>
    )
}

export default DeviceDetect;