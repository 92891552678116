import {createStore, applyMiddleware, combineReducers} from 'redux';
import logger from 'redux-logger';
import { loginReducer } from './reducer/login.reducer';
import { adminLoginReducer } from './reducer/adminLogin.reducer';

const rootReducer = combineReducers({
    login: loginReducer,
    adminLogin: adminLoginReducer 
})
const middleWare = [logger]
export const store = createStore(rootReducer, applyMiddleware(...middleWare));