import React, { useState } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import axios from "axios";
import { Fetch } from "../../services/dataservice";
import { connect } from 'react-redux';
import { signin, admin } from "../../redux/action/adminLogin.action";
import Cookies from 'js-cookie';

function AdminLogin({ history, loginStatus, loginInfo }) {
    //const [redirect, setRedirect] = useState(false); 
    const [credentials, setCredentials] = useState({
        email: null,
        password: null
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const [iconClass, setIconClass] = useState('fa fa-eye-slash eyestyle');    
    const togglePasswordVisiblity = () => {        
        if (!passwordShown) {
            setPasswordShown(true);
            setIconClass('fa fa-eye eyestyle');
            return;
        }
        setPasswordShown(false);
        setIconClass('fa fa-eye-slash eyestyle');
    };

    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    const handleAuthenticate = async () => {
        try {
            const userAuthenticate = await Fetch(`/AdminValidate/Login`, "post", credentials);
            if (userAuthenticate.uid > 0) {
                const adm = [{ aId: userAuthenticate.uid, fName: userAuthenticate.givenName }];
                loginInfo(adm);
                loginStatus(true);
                //setRedirect(true);
                history.push('/admin');
            } else {
                alert('You have entered an invalid email or password.');
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };
    // if(redirect){
    //     return <Redirect to='/member' />;
    // }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            <div className="form-container">
                                <Link to="/member-login"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: 'right' }}></i></Link>
                                <div className="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Admin Login</h3>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" onChange={(e) => handleChange(e, "email")} className="form-control" />
                                </div>
                                <div className="form-group" style={{ position: 'relative' }}>
                                    <label>Password</label>                                    
                                    <input type={passwordShown ? 'text' : 'password'} className="form-control" onChange={(e) => handleChange(e, "password")} />
                                    <i class={iconClass} onClick={togglePasswordVisiblity}></i>
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleAuthenticate}>Submit</button>
                                </div>
                                {/* <div className="form-group text-center">
                                    <Link to="#" style={{ color: "#fff", lineHeight: "35px" }}>Forgot Password</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    loginStatus: (IsLogin) => dispatch(signin(IsLogin)),
    loginInfo: (AdminInfo) => dispatch(admin(AdminInfo))
})

export default connect(null, mapDispatchToProps)(withRouter(AdminLogin));