import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Fetch } from "../../services/dataservice";
import { connect } from 'react-redux';
import MenuAdmin from './MenuAdmin';

function GenerateGiftCards({ history, adminInfo }) {
    const adminId = parseInt(adminInfo.map((item) => item.aId));
    const [numberOfGiftCards, setNumberOfGiftCards] = useState(0);
    const [giftCardData, setGiftCardData] = useState({
        adminID: adminId,
        agentName: null,
        amount: 0,
        expiryDate: null,
        message: null
    });

    const handleChange = (e, property) => {
        setGiftCardData({ ...giftCardData, [property]: e.target.value });
    };
    const handleChangeFloat = (e, property) => {
        setGiftCardData({ ...giftCardData, [property]: parseFloat(e.target.value) });
    };

    const handleGenerateGiftCards = async () => {
        try {            
            if (giftCardData.amount >= 1 && numberOfGiftCards >= 1) {
                let data = await Fetch(`/GiftCards/Generate/` + numberOfGiftCards, "post", giftCardData);
                if (data.result == 'success') {
                    history.push({
                        pathname: '/admin/print-gift-cards',
                        state: data.startGCID
                    });
                } else {
                    alert('Error in generating gift cards.');
                }
            } else {
                alert('Gift cards amount & number must be more than or equal to 1.');
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Generate Gift Cards</h3>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Agent Name</label>
                                    <TextBoxComponent cssClass="e-outline input-border" onChange={(e) => handleChange(e, "agentName")} />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Amount</label>
                                    <NumericTextBoxComponent format='c2' min={1}
                                        cssClass="e-outline input-border" onChange={(e) => handleChangeFloat(e, "amount")} />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Number of Gift Cards</label>
                                    <NumericTextBoxComponent format='###' min={1} cssClass="e-outline input-border" onChange={e => setNumberOfGiftCards(parseInt(e.target.value))} />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Message</label>
                                    <TextBoxComponent cssClass="e-outline input-border" onChange={(e) => handleChange(e, "message")} />
                                </div>
                                <div class="col-md-12 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handleGenerateGiftCards}>Generate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ adminLogin: { adminInfo } }) => ({
    adminInfo
})

export default connect(mapStateToProps)(withRouter(GenerateGiftCards));