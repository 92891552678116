import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Inject, Page, Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import { connect } from 'react-redux';
import MenuMember from './MenuMember';
import Modal from 'react-modal';
import dayjs from 'dayjs';

Modal.setAppElement('#root');

const PaymentHistory = ({ memberInfo }) => {
    const memberId = parseInt(memberInfo.map((item) => item.mId));
    
    const [gridTransaction, setGridTransaction] = useState([]);
    const [viewTransaction, setViewTransaction] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [paging, setPaging] = useState(false);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Transactions/GetTransactionByMemberId/` + memberId)
            .then((response) => {
                setGridTransaction(response.data);
                if (response.data.length > 10) {
                    setPaging(true);
                }
            });
    }, []);

    const toolbarOptions = [
        "Search"
    ];
    const pageSettingsOptions = {
        pageCount: 3, pageSize: 10, pageSizes: ['10', '20', '30', '40', '50'] //pageSizes: true        
    };

    const rowDataBound = args => {
        // if (args.row.ariaRowIndex !== '0') {
        //     // args.row.cells[5].style.display = 'none';
        //     args.row.cells[5].className = 'e-rowcell e-templatecell display-none';            
        // }
    };

    const handleClose = () => setModalIsOpen(false);

    const handleDetailView = (txnID) => {
        try {
            setModalIsOpen(true);
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Transactions/GetTransactionByTransactionId/` + txnID)
                .then((response) => {
                    setViewTransaction(response.data);
                });
            setIsLoaded(true);
        }
        catch (ex) {
            setModalIsOpen(false);
            alert('Error...');
        }
    }

    // Format the price to USD using the locale, style, and currency.
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Payment History</h3>
                                </div>
                                <div>
                                    <GridComponent id='grdTxn' dataSource={gridTransaction} allowSorting={true} height='auto' rowDataBound={rowDataBound}
                                        allowPaging={paging} pageSettings={pageSettingsOptions} toolbar={toolbarOptions} >
                                        <ColumnsDirective>
                                            <ColumnDirective field='txnID' headerText='Txn Id' width='80' textAlign='left' visible={false} />
                                            <ColumnDirective field='txnDate' headerText='Transaction Date' width='150' textAlign='left' type='datetime' format='MM/dd/yyyy HH:mm' />
                                            <ColumnDirective field='txnExpiryDate' headerText='Plan Expiry Date' width='150' textAlign='left' type='datetime' format='MM/dd/yyyy HH:mm' />
                                            <ColumnDirective field='termPlan' headerText='Plan Term(month)' width='150' textAlign='left' />
                                            <ColumnDirective width="60"
                                                template={({ txnID }) => (
                                                    <button className='iconadmin' title='Details' onClick={() => handleDetailView(txnID)}>View</button>
                                                )}
                                            />
                                            {/* <ColumnDirective width="100"
                                                template={({ memberID }) => (
                                                    <Link to='/member/member-payment' style={{ color: '#108896', cursor: 'pointer' }}>Renew Plan</Link>
                                                )}
                                            /> */}
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Toolbar]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal isOpen={modalIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto',
                                height: '500px'
                            }
                        }
                    }
                >
                    <div>
                        <button className="crossbtn" onClick={handleClose} title='Close'>X</button>
                    </div>
                    <div className="modalcontent">
                        {!isLoaded ?
                            // <p style={{ marginTop: '150px' }}><img src='/assets/img/loading.gif' alt='' style={{ width: '100%' }} /></p>
                            <p style={{ marginTop: '150px', marginLeft: '250px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '60px', color: 'black' }}></i></p>
                            :
                            (
                                <div>
                                    <p style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>Payment Details</p><br />
                                    <p>
                                        Transaction Date: <span className='modaldata'>{dayjs(viewTransaction.txnDate).format("MM/DD/YYYY HH:mm")}</span><br />
                                        Plan Expiry Date: <span className='modaldata'>{dayjs(viewTransaction.txnExpiryDate).format("MM/DD/YYYY HH:mm")}</span><br />
                                        Plan Term(month): <span className='modaldata'>{viewTransaction.termPlan}</span><br />
                                        Amount: <span className='modaldata'>{USDollar.format(viewTransaction.txnAmount)}</span><br />
                                        Card Number: <span className='modaldata'>XXXX-XXXX-XXXX-{viewTransaction.cardNumber}</span><br />
                                        Card Type: <span className='modaldata'>{viewTransaction.cardType}</span><br />
                                        Gift Code: <span className='modaldata'>{viewTransaction.code}</span><br />
                                        Payment ID: <span className='modaldata'>{viewTransaction.gatewayTxnID}</span><br />
                                        Status: <span className='modaldata'>{viewTransaction.payStatus}</span>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </Modal>
            </div>
        </div>
    );
}

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps)(withRouter(PaymentHistory));