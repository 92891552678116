import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import InputMask from 'react-input-mask';
import CMSMenu from './CMSMenu';

function MedicalRegistration({ history }) {
    const location = useLocation();
    const [memberData, setMemberData] = useState({
        memberID: 0,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        phone: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        latitude: 0,
        longitude: 0,
        homeType: null,
        multiLevel: false,
        levelNumber: null,
        fastestAccess: null,
        roomNumber: null,
        bestAccessToEnter: null,
        referredBy: null,
        buildingType: null,
        constructionType: null,
        numberOfStories: null,
        disabilities: false,
        healthConditions: null,
        careTakerPhone: null,
        careTakerName: null,
        caregiver: false,
        pets: [],
        emergencyContacts: []
    });
    const [healthInfo, setHealthInfo] = useState([
        { Id: 1, healthType: 'Diabetes', healthStatus: false },
        { Id: 2, healthType: 'Epilepsy', healthStatus: false },
        { Id: 3, healthType: 'Hypertension', healthStatus: false },
        { Id: 4, healthType: 'Non-ambulatory', healthStatus: false },
        { Id: 5, healthType: 'Oxygen', healthStatus: false },
        { Id: 6, healthType: 'Wheelchair', healthStatus: false }
    ]);

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-registration');
        } else {
            memberData.email = location.state.email;
            memberData.password = location.state.password;
            memberData.firstName = location.state.firstName;
            memberData.lastName = location.state.lastName;
            memberData.dateOfBirth = location.state.dateOfBirth;
            memberData.phone = location.state.phone;
            memberData.address = location.state.address;
            memberData.city = location.state.city;
            memberData.state = location.state.state;
            memberData.zip = location.state.zip;
            memberData.homeType = location.state.homeType;
            memberData.multiLevel = location.state.multiLevel;
            memberData.levelNumber = location.state.levelNumber;
            memberData.fastestAccess = location.state.fastestAccess;
            memberData.roomNumber = location.state.roomNumber;
            // memberData.bestAccessToEnter = location.state.bestAccessToEnter;
            memberData.referredBy = location.state.referredBy;
            memberData.buildingType = location.state.buildingType;
            memberData.constructionType = location.state.constructionType;
            memberData.numberOfStories = location.state.numberOfStories;
            memberData.pets = location.state.pets;
            memberData.emergencyContacts = location.state.emergencyContacts;
        }
    }, []);

    const handleChange = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value });
    };
    const handleChangeUnmask = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value.replace(/[^0-9]+/g, '') });
    };
    const handleChangeBool = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value.toLowerCase() === 'true' });
    };
    const handleProfileMedicalNext = () => {
        let health = '';
        healthInfo.forEach((data) => {
            if (data.healthStatus) {
                health = health + data.healthType + ';';
            }
        })
        memberData.healthConditions = health.trim();

        if (!memberData.caregiver) {
            memberData.careTakerPhone = '';
            memberData.careTakerName = '';
        }

        history.push({
            pathname: '/police-registration',
            state: memberData
        });
    }
    const handleProfileMedicalSkip = () => {
        history.push({
            pathname: '/police-registration',
            state: memberData
        });
    }

    return (
        <div>
            <div style={{ float: "left" }}>
                <div class="int-container desktop">
                    <img src="/assets/img/int-bg.jpg" alt="" />
                </div>
                <div class="int-container mobile">
                    <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
                </div>
                <div class="main-container">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6 text-right">
                                <CMSMenu />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 text-center">
                            </div>
                            <div class="col-md-7">
                                <div class="form-container">
                                    <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                    <div class="form-group">
                                        <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Medical</h3>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 form-group">
                                            <label>Disabilities</label>
                                            <RadioButtonComponent label='Yes' name='disability' value='true' onChange={(e) => handleChangeBool(e, "disabilities")} checked={memberData.disabilities} />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <RadioButtonComponent label='No' name='disability' value='false' onChange={(e) => handleChangeBool(e, "disabilities")} checked={!memberData.disabilities} />
                                        </div>
                                        <div class="col-md-9 form-group">
                                            <label>Health Conditions</label>
                                            <div style={{ marginBottom: '15px', float: 'left' }}>
                                                {healthInfo.map((data, index) => (
                                                    <div key={index} style={{ width: '33%', marginBottom: '5px', float: 'left' }}>
                                                        <CheckBoxComponent checked={data.healthStatus} label={data.healthType} value={data.healthType}
                                                            onChange={(e) => {
                                                                data.healthStatus = e.target.checked;
                                                                setHealthInfo([...healthInfo]);
                                                            }} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div class="col-md-3 form-group">
                                            <label>Caregiver</label>
                                            <RadioButtonComponent label='Yes' name='caregiver' value='true' onChange={(e) => handleChangeBool(e, "caregiver")} checked={memberData.caregiver} />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <RadioButtonComponent label='No' name='caregiver' value='false' onChange={(e) => handleChangeBool(e, "caregiver")} checked={!memberData.caregiver} />
                                        </div>
                                        {memberData.caregiver === true &&
                                            <>
                                                <div class="col-md-4 form-group">
                                                    <label>&nbsp;</label>
                                                    <InputMask className='textmasking' mask='(999) 999-9999' placeholder="Mobile" value={memberData.careTakerPhone} onChange={(e) => handleChangeUnmask(e, "careTakerPhone")} />
                                                </div>
                                                <div class="col-md-5 form-group">
                                                    <label>&nbsp;</label>
                                                    <TextBoxComponent cssClass="e-outline input-border" placeholder="Name" value={memberData.careTakerName} onChange={(e) => handleChange(e, "careTakerName")} />
                                                </div>
                                            </>
                                        }
                                        {/* <div class="col-md-6 form-group">
                                            <button className="btn btn-block btn-primary" onClick={handleProfileMedicalSkip}>Skip</button>
                                        </div> */}
                                        <div class="col-md-12 form-group">
                                            <button className="btn btn-block btn-primary" onClick={handleProfileMedicalNext}>Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MedicalRegistration);