import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import axios from "axios";
import CMSMenu from './CMSMenu';
import Modal from 'react-modal';
//import { Fetch } from "../services/dataservice";

Modal.setAppElement('#root');

function MemberRegistration({ history }) {
    const [messageDiv, setMessageDiv] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [smsConsent, setSMSConsent] = useState(false);
    const [termsConditions, setTermsConditions] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [credentials, setCredentials] = useState({
        email: null,
        password: null,
        confpassword: null
    });

    const [passwordShown, setPasswordShown] = useState({
        pwd: false,
        confPwd: false
    });
    const [iconClass, setIconClass] = useState({
        pwd: 'fa fa-eye-slash eyestyle',
        confPwd: 'fa fa-eye-slash eyestyle'
    });
    const togglePasswordVisiblity = (str) => {
        if (str === 'psd') {
            if (!passwordShown.pwd) {
                setPasswordShown({ ...passwordShown, pwd: true });
                setIconClass({ ...iconClass, pwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, pwd: false });
            setIconClass({ ...iconClass, pwd: 'fa fa-eye-slash eyestyle' });
        } else if (str === 'confpsd') {
            if (!passwordShown.confPwd) {
                setPasswordShown({ ...passwordShown, confPwd: true });
                setIconClass({ ...iconClass, confPwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, confPwd: false });
            setIconClass({ ...iconClass, confPwd: 'fa fa-eye-slash eyestyle' });
        }
    };

    // const [mailRequest, setMailRequest] = useState({
    //     toEmail: 'pkmohanty03@gmail.com',
    //     subject: 'Registration OTP',
    //     body: 'OTP: 12345',
    //     attachments: null
    // });

    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    const handleClose = () => setModalIsOpen(false);
    const handleAccept = () => {
        if (termsConditions && smsConsent) {
            history.push({
                pathname: '/update-profile-new',
                state: credentials
            });
        } else {
            alert('Please read and accept the terms and conditions & consent to receive text messages before proceeding.');
        }
    }
    const handleSMSConsentCheck = (e) => {
        setSMSConsent(e.target.checked);
    }
    const handleTermsCheck = (e) => {
        setTermsConditions(e.target.checked);
    }

    const handleRegistration = async () => {
        // let result = await Fetch(`/Email/Send`, "post", mailRequest);        
        // if (result.status === 'success') {
        //     alert('OTP sent to your email.');
        // } else {
        //     alert('Error on sending OTP.');                                
        // }

        try {
            if (credentials.email) {
                if (credentials.password !== credentials.confpassword) {
                    setErrorMessage("Passwords don't match.");
                    setMessageDiv(true);
                } else {
                    axios
                        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetByEmail/` + credentials.email)
                        .then((response) => {
                            if (response.data > 0) {
                                setErrorMessage('Email already exists.');
                                setMessageDiv(true);
                            } else {
                                setMessageDiv(false);
                                setModalIsOpen(true);
                            }
                        });
                }
            } else {
                setErrorMessage('Enter a valid email address.');
                setMessageDiv(true);
            }
        }
        catch (ex) {
            setModalIsOpen(false);
            alert('Error...');
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6 text-right">
                            <CMSMenu />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            {messageDiv &&
                                <div className='errorMsg'>
                                    <i class="fa fa-warning warn"></i>
                                    <span style={{ display: 'inline-block', lineHeight: '27px' }}>{errorMessage}</span>
                                </div>
                            }
                            <div class="form-container">
                                <p style={{ textAlign: 'right', fontSize: '15px' }}>Registered member?&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Link to="/member-login" style={{ color: '#f19d19' }}>Sign-In</Link>
                                </p>
                                {/* <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link> */}
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Create an Account</h3>
                                </div>
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" required class="form-control" onChange={(e) => handleChange(e, "email")} />
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>Password</label>
                                    <input type={passwordShown.pwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "password")} />
                                    <i class={iconClass.pwd} onClick={() => togglePasswordVisiblity('psd')}></i>
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>Confirm Password</label>
                                    <input type={passwordShown.confPwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "confpassword")} />
                                    <i class={iconClass.confPwd} onClick={() => togglePasswordVisiblity('confpsd')}></i>
                                </div>
                                <div class="form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleRegistration}>Continue</button>
                                    <a style={{ color: 'rgb(241, 157, 25)', lineHeight: '50px', fontSize: '18px' }} href='https://myemergencynetwork.com/How%20it%20works.shtml' target='_blank'>How it works</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a style={{ color: 'rgb(241, 157, 25)', lineHeight: '50px', fontSize: '18px' }} href='/assets/media/recording000000.mp4' target='_blank'>About this app</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal isOpen={modalIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto',
                                height: '370px'
                            }
                        }
                    }
                >
                    <div>
                        <button className="crossbtn" onClick={handleClose} title='Close'>X</button>
                    </div>
                    <div className="modalcontent" style={{ fontSize: '18px', marginBottom: '0' }}>
                        <div style={{ clear: 'both', margin: '40px 0 10px' }}>
                            Your subscription entitles you to a Free 30-days subscription.<br />
                            All users under the age of 18 with a parent in the same location, will receive Free membership until their 18th birthday. After that, the user will need to obtain a subscription under their own account.<br /><br />                            
                            <CheckBoxComponent checked={termsConditions} onChange={(e) => handleTermsCheck(e)} />&nbsp;&nbsp;
                            I accept the <a href='https://myemergencynetwork.com/Terms%20and%20Conditions.shtml' target='_blank' style={{ color: '#ef5030' }}>Terms and Conditions</a>.
                            <br/>
                            <CheckBoxComponent checked={smsConsent} onChange={(e) => handleSMSConsentCheck(e)} />&nbsp;&nbsp;
                            I consent to receive text messages from My Emergency Network.<br />
                            <span style={{ fontSize: '14px', paddingLeft: '34px', color: '#000' }}>Note: You will always have the option to opt-out of text messages by replying STOP.</span>
                        </div>
                        <div>
                            <button className="btn btn-block btn-primary" onClick={handleAccept}>Submit</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default withRouter(MemberRegistration);