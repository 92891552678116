import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import DOMPurify from "dompurify";
import MapEmergency from './MapEmergency';

const Info911 = () => {
    const { call } = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = e.split('=')[1], r), {});           
   
    const [emrCall, setEmrCall] = useState({
        callID: call
    });
    const [emrInfo, setEmrInfo] = useState({});    
    const [memInfo, setMemInfo] = useState({});
    const [emrFileInfo, setEmrFileInfo] = useState([]);
    const [memberAge, setMemberAge] = useState(0);
    // const [disability, setDisability] = useState('No');    
    // const [hlConditions, setHLConditions] = useState('');    
    const [memName, setMemName] = useState();
    const [memFullAddress, setMemFullAddress] = useState();
    const [homeType, setHomeType] = useState();
    const [locationPets, setLocationPets] = useState();
    const [othersContact, setOthersContact] = useState();
    const [othersContactHtml, setOthersContactHtml] = useState();    
    const [showChild, setShowChild] = useState(false);
    const [emrType, setEmrType] = useState('');

    useEffect(() => {
        try {
            if (emrCall.callID) {
                axios                    
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/EmergencyCalls/GetById/` + emrCall.callID)
                    .then((response) => {
                        if (response.data) {
                            setEmrInfo(response.data);
                            setEmrFileInfo(response.data.ecFiles);                            
                            setEmrType(response.data.emergencyType);                                                     
                            
                            let emerAddress = '';
                            if (response.data.latitude != 0 && response.data.longitude != 0) {
                                setShowChild(true);
                                emerAddress = 'Latitude: ' + response.data.latitude + ' / Longitude: ' + response.data.longitude;
                            }

                            getMemberData(response.data.memberID, response.data.atHome, emerAddress, response.data.callDate);
                        }
                    });                    
            }            
        }
        catch (ex) {
            alert('Error...');
        }
    }, []);

    const getMemberData = (mId, atHome, emerAddress, callDate) => {
        if (mId > 0) {
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetById/` + mId)
                .then((response) => {                    
                    setMemInfo(response.data);
                    fillMemberData(response.data, atHome, emerAddress, callDate);                   
                });
            
            // axios
            //     .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetHomePlanById/` + mId)
            //     .then((response) => {
            //         if (response.data) {
                        
            //         } else {
                        
            //         }
            //     });
        }        
    }

    const formatPhoneNumber = (value) => {
        if (!value) return value;

        const phoneNumber = value.replace(/[^\d]/g, '');

        if (phoneNumber.length < 4) return phoneNumber;

        if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    const fillMemberData = (data, atHome, emerAddress, callDate) => {
        // if (data.disabilities) {
        //     setDisability('Yes');
        // }
        // if (data.healthConditions) {
        //     let subStr = data.healthConditions.substring(0, data.healthConditions.length - 1);            
        //     let newStr = subStr.replace(new RegExp(';', 'g'), ', ')     // global flag g, to replace all digits
        //     setHLConditions(newStr);
        // }
        
        if (data.dateOfBirth && callDate) {
            let dtDOB = new Date(data.dateOfBirth);
            let dtEmr = new Date(callDate);
            if (dtEmr > dtDOB) {            
                setMemberAge(Math.floor((dtEmr.getTime() - dtDOB.getTime()) / (1000 * 60 * 60 * 24 * 365)));
                // getTime() returns the number of milliseconds since January 1, 1970 00:00:00            
            }            
        }                
        
        setMemName(data.firstName + ' ' + data.lastName);        
        
        if (atHome.toLowerCase() === 'yes') {
            setMemFullAddress(data.address + ', ' + data.city + ', ' + data.state + ', ' + data.zip);

            let hType = data.homeType;               
            if (data.homeType === 'Apartment' || data.homeType === 'Townhouse') {
                if (data.multiLevel) {
                    hType += ', Multi-level';
                    if (data.levelNumber) {
                        hType += ', Floor - ' + data.levelNumber;
                    }
                }                       
            } else if (data.homeType === 'High Rise') {
                if (data.levelNumber) {
                    hType += ', Floor - ' + data.levelNumber;
                }
                if (data.roomNumber) {
                    hType += ', Room # - ' + data.roomNumber;
                }                       
            } 
            // if (data.homeType && data.fastestAccess) {
            //     hType += ', Fastest way to access emergency location - ' + data.fastestAccess;
            // }          
            setHomeType(hType);

            let locPets = '';
            data.pets.forEach((item, index) => {
                if (item.petNature) {
                    locPets += item.petType + '(Aggressive)';
                } else {
                    if (item.petType.toLowerCase() === 'other')
                        locPets += item.petType + '(' + item.notes + ')';                 
                    else
                        locPets += item.petType;
                }

                if (index < data.pets.length - 1) {
                    locPets += ', ';
                }                
            })                
            setLocationPets(locPets);
        } else {
            setMemFullAddress(emerAddress);
        }

        let contactsHtml = '';
        data.emergencyContacts.forEach((item) => {
            let contactInfo = '';
            if (item.name) {
                contactInfo += item.name;                
            }
            if (item.phone) {
                if (contactInfo !== '')
                    contactInfo +=  ', ' + formatPhoneNumber(item.phone);
                else
                    contactInfo +=  formatPhoneNumber(item.phone);
            }
            if (item.address) {
                if (contactInfo !== '')
                    contactInfo +=  ', ' + item.address;
                else
                    contactInfo +=  item.address;                
            }
            
            if (contactInfo?.trim()) {
                contactsHtml += "<span style='width:100%; padding-top: 0; display: inline-block;'>" + contactInfo + '</span>';
                setOthersContact(contactInfo);
            }                          
        })
        let contacts = <div style={{width: '100%', padding: '0'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contactsHtml) }} />; 
        setOthersContactHtml(contacts);
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="form-container">
                                <div class="form-group">
                                    <h5 style={{ color: "#fff" }}>Detail Information</h5>
                                    <div style={{ padding: '5px 0' }}>
                                        {showChild &&
                                            <MapEmergency                                                
                                                center={{ lat: emrInfo.latitude, lng: emrInfo.longitude }}
                                                height='300px'
                                                zoom={15}
                                            />
                                        }
                                    </div>
                                    <table id="info" width="100%">
                                        <tbody>
                                            <tr>
                                                <td>Emergency:</td>
                                                <td><b style={{ color: '#f19f1f' }}>{emrType.toUpperCase()}</b></td>
                                            </tr>                                          
                                            {emrType.toLowerCase() === 'fire' &&
                                                <>
                                                    {emrInfo.specificLocationOfFire?.trim() &&
                                                        <tr>
                                                            <td>Specific location of the fire:</td>
                                                            <td>{emrInfo.specificLocationOfFire}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.storyType?.trim() &&
                                                        <tr>
                                                            <td>Building type:</td>
                                                            <td>{emrInfo.storyType}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.fireStarted?.trim() &&
                                                        <tr>
                                                            <td>Fire startedfireStarted in:</td>
                                                            <td>{emrInfo.fireStarted}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.peopleAtLocation?.trim() &&
                                                        <tr>
                                                            <td>Number of people currently at this location:</td>
                                                            <td>{emrInfo.peopleAtLocation}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.removedToSafety?.trim() &&
                                                        <tr>
                                                            <td>Have you removed yourself/others to safety from this emergency:</td>
                                                            <td>{emrInfo.removedToSafety}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.fireInjuries?.trim() &&
                                                        <tr>
                                                            <td>Injuries associated with this fire:</td>
                                                            <td>{emrInfo.fireInjuries}</td>
                                                        </tr>
                                                    }                                                    
                                                </>
                                            }
                                            {emrType.toLowerCase() === 'medical' &&
                                                <>
                                                    {emrInfo.natureOfEmergencyMedical?.trim() &&
                                                        <tr>
                                                            <td>Nature of emergency:</td>
                                                            <td>{emrInfo.natureOfEmergencyMedical}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.injuredPersonState?.trim() &&
                                                        <tr>
                                                            <td>State of person injured:</td>
                                                            <td>{emrInfo.injuredPersonState}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.injuredPersonAge?.trim() &&
                                                        <tr>
                                                            <td>Age of injured person:</td>
                                                            <td>{emrInfo.injuredPersonAge}</td>
                                                        </tr>
                                                    }
                                                    {/* <tr>
                                                        <td>Disabilities:</td>
                                                        <td>{disability}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Previous health conditions:</td>
                                                        <td>{hlConditions}</td>
                                                    </tr> */}
                                                </>
                                            }
                                            {emrType.toLowerCase() === 'police' &&
                                                <>
                                                    {emrInfo.natureOfEmergencyPolice?.trim() &&
                                                        <tr>
                                                            <td>Nature of emergency:</td>
                                                            <td>
                                                                {emrInfo.natureOfEmergencyPolice}
                                                                {emrInfo.natureOfEmergencyPolice.toLowerCase() === 'other' &&
                                                                    <>                                                        
                                                                        ({emrInfo.natureOfEmergencyPoliceNotes})
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    }
                                                    {emrInfo.requiredMedicalAid?.trim() &&
                                                        <tr>
                                                            <td>Medical aid is required:</td>
                                                            <td>{emrInfo.requiredMedicalAid}</td>
                                                        </tr>
                                                    }
                                                    {emrInfo.weapons?.trim() &&
                                                        <tr>
                                                            <td>Weapons used:</td>
                                                            <td>{emrInfo.weapons}</td>
                                                        </tr>
                                                    }
                                                </>
                                            }
                                            {emrType.toLowerCase() === 'catastrophic' &&
                                                <>
                                                    {emrInfo.natureOfEmergencyCatastrophic?.trim() &&
                                                        <tr>
                                                            <td>Nature of emergency:</td>
                                                            <td>
                                                                {emrInfo.natureOfEmergencyCatastrophic}
                                                                {emrInfo.natureOfEmergencyCatastrophic.toLowerCase() === 'other' &&
                                                                    <>                                                        
                                                                        ({emrInfo.natureOfEmergencyCatastrophicNotes})
                                                                    </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    }                                                       
                                                </>
                                            }
                                            {emrInfo.atHome?.trim() &&
                                                <tr>
                                                    <td>Is at home:</td>
                                                    <td>{emrInfo.atHome}</td>
                                                </tr>
                                            }
                                            {emrInfo.vehicleType?.trim() &&
                                                <tr>
                                                    <td>Need help in:</td>
                                                    <td>{emrInfo.vehicleType}</td>
                                                </tr>
                                            }
                                            {emrInfo.licensePlate?.trim() &&
                                                <tr>
                                                    <td>License plate:</td>
                                                    <td>{emrInfo.licensePlate}</td>
                                                </tr>
                                            }
                                            {emrInfo.vehicleModel?.trim() &&
                                                <tr>
                                                    <td>Vehicle model:</td>
                                                    <td>{emrInfo.vehicleModel}</td>
                                                </tr>
                                            }
                                            {emrInfo.vehicleColor?.trim() &&
                                                <tr>
                                                    <td>Vehicle color:</td>
                                                    <td>{emrInfo.vehicleColor}</td>
                                                </tr>
                                            }
                                            {emrInfo.additionalComments?.trim() &&
                                                <tr>
                                                    <td>Comments:</td>
                                                    <td>{emrInfo.additionalComments}</td>
                                                </tr>
                                            }
                                            {emrFileInfo.length > 0 &&
                                                <tr>
                                                    <td>Image:</td>
                                                    <td>                                                    
                                                        {emrFileInfo.map((data,index) =>
                                                            <span key={index} style={{ display: 'block', width: '100%' }}>
                                                                <Link style={{ color: '#ef5030' }} to={{ pathname: process.env.REACT_APP_IMAGEEMERGENCY_URL + data.fileName }} target='_blank'>{data.fileName}</Link>
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            }
                                            {memName?.trim() &&                                            
                                                <tr>
                                                    <td>Name:</td>
                                                    <td>{memName}</td>
                                                </tr>
                                            }
                                            {memFullAddress?.trim() &&
                                                <tr>
                                                    <td>Address:</td>
                                                    <td>{memFullAddress}</td>
                                                </tr>
                                            }
                                            {memInfo.phone?.trim() &&
                                                <tr>
                                                    <td>Mobile:</td>
                                                    <td>{formatPhoneNumber(memInfo.phone)}</td>                                                
                                                </tr>
                                            }
                                            {memInfo.email?.trim() &&
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{memInfo.email}</td>
                                                </tr>
                                            }
                                            {memberAge > 0 &&
                                                <tr>
                                                    <td>Age:</td>
                                                    <td>{memberAge}</td>
                                                </tr>
                                            }
                                            {homeType?.trim() &&
                                                <tr>
                                                    <td>Home type:</td>
                                                    <td>{homeType}</td>
                                                </tr>
                                            }
                                            {othersContact?.trim() &&
                                                <tr>
                                                    <td>Others contact:</td>
                                                    <td>{othersContactHtml}</td>
                                                </tr>
                                            }
                                            {locationPets?.trim() &&
                                                <tr>
                                                    <td>Pets at location:</td>
                                                    <td>{locationPets}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Info911;