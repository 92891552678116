import React, { useState, useEffect } from 'react';
import { Link, withRouter, useLocation, Redirect } from 'react-router-dom';
import { Fetch } from "../services/dataservice";
import { connect } from 'react-redux';
import { signin, member } from "../redux/action/login.action";
import CMSMenu from './CMSMenu';
import axios from "axios";
import Cookies from 'js-cookie';

function Signin({ history, loginStatus, loginInfo }) {
    //const [redirect, setRedirect] = useState(false);
    const [messageDiv, setMessageDiv] = useState(false);
    const [isSubscriptionValid, setIsSubscriptionValid] = useState(true);
    const location = useLocation();
    const [credentials, setCredentials] = useState({
        email: null,
        password: null
    });

    const [passwordShown, setPasswordShown] = useState(false);
    const [iconClass, setIconClass] = useState('fa fa-eye-slash eyestyle');    
    const togglePasswordVisiblity = () => {        
        if (!passwordShown) {
            setPasswordShown(true);
            setIconClass('fa fa-eye eyestyle');
            return;
        }
        setPasswordShown(false);
        setIconClass('fa fa-eye-slash eyestyle');
    };

    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-login');
        } else {
            setCredentials({ email: location.state.email });
        }
    }, []);

    const handleAuthenticate = async () => {
        try {
            const userAuthenticate = await Fetch(`/MemberValidate/Login`, "post", credentials);
            
            // // set Token
            // const accessToken = userAuthenticate.loginToken;
            // Cookies.set("jwt", accessToken);

            // // get Token
            // let accessToken = Cookies.get("jwt");
            // if (accessToken === undefined)
            // {
            //     //login
            // }
            // if (!accessToken)
            // {
            //     //login
            // } else {
            //     const authAxios = axios.create({
            //         baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
            //         headers:{
            //             Authorization: `Bearer ${accessToken}`
            //         }
            //     });            
            //     let result = await authAxios.get(`/MemberValidate/MembersEndpoint/Members`);
            //     console.log(result.data);
            // }
            
            if (userAuthenticate.uid > 0) {
                const memb = [{ mId: userAuthenticate.uid, fName: userAuthenticate.givenName }];
                loginInfo(memb);
                loginStatus(true);
                if (userAuthenticate.verifyForgotPassword) {
                    history.push({
                        pathname: '/member/change-password',
                        state: true 
                    });                    
                } else {
                    setIsSubscriptionValid(false);
                    axios                    
                        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetSubscriptionStatus/` + userAuthenticate.uid)
                        .then((response) => {
                            if (response.data.status) {
                                //setRedirect(true);
                                history.push('/member');
                            } else {                                
                                alert('Your subscription has expired and needs to be renewed.');
                                history.push('/member/member-payment');
                            }
                        });                                        
                }                
            } else {
                setMessageDiv(true);
            }
        }
        catch (ex) {
            alert('Error...');
            setIsSubscriptionValid(true);
        }
    };

    // if(redirect){
    //     return <Redirect to='/member' />;
    // }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6 text-right">
                            <CMSMenu />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            {messageDiv &&
                                <div className='errorMsg'>
                                    <i class="fa fa-warning warn"></i>
                                    <span style={{ color: '#db0f16', fontSize: '16px' }}>There was a problem</span>
                                    <br />
                                    Your password is incorrect
                                </div>
                            }
                            <div className="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                <div className="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Sign-In</h3>
                                </div>
                                <div className="form-group">
                                    <san style={{ color: '#fff' }}>{credentials.email}</san>&nbsp;&nbsp;
                                    <Link to="/member-login" style={{ color: "#f19d19", lineHeight: "35px" }}>Change</Link>
                                </div>
                                <div className="form-group" style={{ position: 'relative' }}>
                                    <label>
                                        Password
                                        <Link to="/forgot-password"><span style={{ color: '#f19d19', float: 'right' }}>Forgot Password</span></Link>
                                    </label>
                                    <input type={passwordShown ? 'text' : 'password'} className="form-control" onChange={(e) => handleChange(e, "password")} />
                                    <i class={iconClass} onClick={togglePasswordVisiblity}></i>
                                </div>
                                <div className="form-group">
                                    {isSubscriptionValid ?
                                        <button className="btn btn-block btn-primary" onClick={handleAuthenticate}>Sign-In</button>
                                        :
                                        <p className="btn btn-block btn-primary" style={{ cursor: 'auto', padding: '15px 30px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '30px', color: 'white' }}></i></p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    loginStatus: (IsLogin) => dispatch(signin(IsLogin)),
    loginInfo: (MembInfo) => dispatch(member(MembInfo))
})

export default connect(null, mapDispatchToProps)(withRouter(Signin));