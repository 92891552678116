import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Inject, Page, Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import MenuAdmin from './MenuAdmin';

const TransactionsMember = () => {
    const history = useHistory();
    const location = useLocation();
    const memberID = location.state.mId;
    const [memberTransaction, setMemberTransaction] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Transactions/GetTransactionByMemberId/` + memberID)
            .then((response) => {
                setMemberTransaction(response.data);
            });
    }, []);

    const toolbarOptions = [
        "Search"
    ];
    const pageSettingsOptions = {
        pageCount: 3, pageSize: 10, pageSizes: ['10', '20', '30', '40', '50']
    };

    const rowDataBound = args => {
        if (args.row.ariaRowIndex !== '0') {
            //args.row.cells[6].style.display = 'none';
            args.row.cells[6].className = 'e-rowcell e-templatecell display-none';
            args.row.cells[7].className = 'e-rowcell e-templatecell display-none';
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div className='form-container'>
                                <div class="form-group">
                                    <button className="back e-icons e-back e-btn e-flat e-icon-btn" title="Back" onClick={() => history.goBack()}></button>
                                </div>
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Transaction History</h3>
                                </div>
                                <div id='grdAdmin'>
                                    {/* {memberTransaction[0] && (
                                        <strong>
                                            {memberTransaction[0].memberName}
                                        </strong>
                                    )} */}
                                    <GridComponent dataSource={memberTransaction} allowSorting={true} height='auto' rowDataBound={rowDataBound}
                                        allowPaging={true} pageSettings={pageSettingsOptions} toolbar={toolbarOptions}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='txnID' headerText='Txn Id' width='80' textAlign='left' visible={false} />
                                            <ColumnDirective field='txnDate' headerText='Txn Date' width='100' textAlign='left' type='datetime' format='MM/dd/yyyy' />
                                            <ColumnDirective field='txnExpiryDate' headerText='Expiry Date' width='100' textAlign='left' type='datetime' format='MM/dd/yyyy' />
                                            <ColumnDirective field='termPlan' headerText='Plan Term(m)' width='110' textAlign='Center' />
                                            <ColumnDirective field='txnAmount' headerText='Amount' format="C2" width='100' textAlign='right' />
                                            <ColumnDirective width="60"
                                                template={({ txnID }) => (
                                                    <Link to='#' style={{ color: '#108896' }}>View</Link>
                                                )}
                                            />
                                            <ColumnDirective width="100"
                                                template={({ memberID }) => (
                                                    <Link to='#' style={{ color: '#108896', cursor: 'pointer' }}>Renew Plan</Link>
                                                )}
                                            />
                                            <ColumnDirective width="150"
                                                template={({ memberID }) => (
                                                    <Link to='#' style={{ color: '#ef5030', cursor: 'pointer' }}>Cancel Membership</Link>
                                                )}
                                            />
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Toolbar]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(TransactionsMember);