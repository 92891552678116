import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { Fetch } from "../../services/dataservice";
import { withRouter } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Inject, Page, Sort, Toolbar
} from "@syncfusion/ej2-react-grids";
import MenuAdmin from './MenuAdmin';
import Modal from 'react-modal';
import TextEditor from './TextEditor';
import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";

Modal.setAppElement('#root');

const SendNotifications = () => {
    const [memberData, setMemberData] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [notificationInfo, setNotificationInfo] = useState({
        toEmail: '',        
        subject: '',
        body: '',
        attachments: null
    });

    const handleClose = () => setModalIsOpen(false);    
    
    let editorHTML;    
    const handleEditorContent = (content) => {        
        editorHTML = content;
        // console.log(editorHTML);        
    }
    
    const [sanitizedData, setSanitizedData] = useState('');
    const handlePreview = () => {
        setModalIsOpen(true);            
        setSanitizedData(DOMPurify.sanitize(editorHTML));
    }

    let grid;
    let textbox = useRef(null);     
    const [selectedRowIndexes, setSelectedRowIndexes] = useState([]);
    const selectionSettings = { checkboxOnly: true, persistSelection: true, type: 'Multiple' };
    const toolbarOptions = [
        'Search'
    ];
    const pageSettingsOptions = {
        pageCount: 3, pageSize: 25, pageSizes: ['All', '25', '50', '100', '200']
    };
    const rowSelected = () => {
        if (grid) {
            setSelectedRowIndexes(grid.getSelectedRowIndexes().sort());
            // console.log(grid.getCellFromIndex(0, 4).innerHTML);   // grid cell value
        }
    }
    const rowDeselected = () => {
        if (grid) {
            setSelectedRowIndexes(grid.getSelectedRowIndexes());
        }
    }

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetAllMemberGridNotification`)
            .then((response) => {
                setMemberData(response.data);
            });
    }, []);

    const sendHandler = async (e) => {
        e.preventDefault();
        if (selectedRowIndexes && selectedRowIndexes.length > 0) {
            let membersEmail = '';            
            selectedRowIndexes.forEach((row) => {
                if (memberData[row]['email']) {
                    membersEmail = membersEmail + memberData[row]['email'].trim() + ';';
                }                
            });            
                        
            notificationInfo.toEmail = membersEmail;            
            notificationInfo.subject = textbox.current.value;
            notificationInfo.body = editorHTML;            
            // console.log(notificationInfo);
            
            try {                
                if (notificationInfo.toEmail && notificationInfo.subject && notificationInfo.body) {
                    setButtonDisabled(true);
                    setLoaded(true);

                    let result = await Fetch(`/Member/SendNotification`, "post", notificationInfo);

                    if (result.status === 'success') {
                        alert('Email & SMS sent successfully.');
                        resetInput();   // reset input value
                    } else {            
                        alert('Error on sending email & SMS.');
                    }                  
                } else {
                    alert('Enter Subject & Content.');
                }                                
            }
            catch (ex) {                
                alert('Error on sending email & SMS.');
            }
            finally {
                setButtonDisabled(false);
                setLoaded(false);
            }
        } else {
            alert('No members selected.');
        }
    };
    
    const resetInput = () => {        
        textbox.current.value = null;                
        // setSelectedRowIndexes(null);
        // notificationInfo.toEmail = null;
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div className='form-container'>
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Send Notification</h3>
                                </div>
                                <div className='row'>
                                    <div class="col-md-12 form-group">
                                        <label>Subject</label>
                                        <TextBoxComponent cssClass="e-outline input-border" ref={textbox} />
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <label>Content</label>                                        
                                        <TextEditor getEditorContent={handleEditorContent} />
                                        <button className='previewbtn' onClick={() => handlePreview()}>Preview</button>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <button className="btn btn-primary" style={{ padding: '10px 20px', marginBottom: '10px' }} onClick={sendHandler} disabled={isButtonDisabled}>Send</button>
                                        {isLoaded &&
                                            <div style={{ position: 'absolute', top: '17px', left: '106px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '25px', color: '#fff' }}></i></div>
                                        }
                                    </div>
                                </div>
                                <div id='grdAdmin'>
                                    <GridComponent ref={g => grid = g} dataSource={memberData} allowSorting={false} allowPaging={true} selectionSettings={selectionSettings}
                                        pageSettings={pageSettingsOptions} toolbar={toolbarOptions} rowSelected={rowSelected} rowDeselected={rowDeselected} height='auto'>
                                        <ColumnsDirective>
                                            <ColumnDirective type='checkbox' width='50' />
                                            <ColumnDirective field='memberID' headerText='Member Id' width='70' textAlign='left' visible={false} isPrimaryKey="true" />
                                            <ColumnDirective field='mName' headerText='Name' width='130' textAlign='left' />
                                            <ColumnDirective field='email' headerText='Email' width='150' textAlign='left' />
                                            <ColumnDirective field='phone' headerText='Mobile' width='100' textAlign='left' />
                                            <ColumnDirective field='txnExpiryDate' headerText='Plan Exp.' width='110' textAlign='left' type='datetime' format='MM/dd/yyyy' />
                                            <ColumnDirective field='mStatus' headerText='Status' width='80' textAlign='left' />
                                            <ColumnDirective field='freeSubscription' headerText='Free Subscription' width='100' textAlign='left' />
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Toolbar]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal id='PreviewModal' isOpen={modalIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto 0',
                                height: '600px'
                            }
                        }
                    }
                >
                    <div>
                        <button className="crossbtn" onClick={handleClose} title='Close'>X</button>
                    </div>
                    <div className="modalcontent">
                        {ReactHtmlParser(sanitizedData)}
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default withRouter(SendNotifications);