import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset } from "../../redux/action/adminLogin.action";

function MenuAdmin({ history, clearState, adminInfo, isLogin }) {
    const adminName = adminInfo.map((item) => item.fName);

    useEffect(() => {
        if (!isLogin) {            
            history.push('/admin/admin-login');
        }
    }, []);

    const handleLogout = () => {
        clearState();
        history.push('/admin/admin-login');
    };

    return (
        <div>
            <div style={{ float: 'right' }}>
                <div class="cart">
                    <span className='memberName'><i class="fa fa-user" style={{ color: "#f19f1f" }}></i>&nbsp;&nbsp;{adminName}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i class="fa fa-bars" style={{ fontSize: '25px' }}></i>
                    <div id="sidebar">
                        <ul>
                            <li><Link to="/admin/manage-members">Members</Link></li>                            
                            <li><Link to="/admin/transactions-report">Transactions</Link></li>
                            {/* <li><Link to="/member/calls-report">Calls</Link></li> */}                            
                            <li><Link to="/admin/manage-gift-cards">Gift Cards</Link></li>
                            <li><Link to="/admin/send-notifications">Send Notifications</Link></li>
                            <li><Link to="/admin/mail-emergency-contacts">Mail Emerg. Contacts</Link></li>
                            <li><Link to="/admin/manage-county-emergency-contact">County Emergency</Link></li>
                            <li><Link to="/admin/admin-change-password">Change Password</Link></li>
                            {/* <li><Link to="/admin/generate-gift-cards">Generate Gift Cards</Link></li> */}
                            {/* <li><a href="https://myemergencynetwork.com/cms/" target="_blank">CMS</a></li> */}                            
                            <li>
                                <button className="btntrans" onClick={handleLogout}>
                                    <i class="fa fa-sign-out" style={{ color: "#ea1b22" }} aria-hidden="true"></i> &nbsp;Sign Out
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    clearState: () => dispatch(reset())
})

const mapStateToProps = ({ adminLogin: { adminInfo, isLogin } }) => ({
    adminInfo,
    isLogin
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuAdmin));