import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Fetch } from '../services/dataservice';
import CMSMenu from './CMSMenu';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

function ForgotPassword({ history }) {
    const [captchaText, setCaptchaText] = useState('');

    const [isLoaded, setIsLoaded] = useState(false);
    const [credentials, setCredentials] = useState({
        email: ''
    });
    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    useEffect(() => {
        loadCaptcha();        
    }, []);

    const loadCaptcha = () => {
        loadCaptchaEnginge(5, '#FFEFD5', '#000', 'lower');  // Number of captcha charcters are 5 & lower(upper/numbers/special_char)
    }

    const handleSubmit = async () => {
        if (validateCaptcha(captchaText) === true) {
            loadCaptcha();
            setCaptchaText('');
            
            setIsLoaded(true);
            try {                
                let result = await Fetch(`/Member/ForgotPassword`, "put", credentials);                
                if (result.message == 'success') {
                    setCredentials({ email: '' });                              
                    alert('Password has been generated & sent to your email address. Also, check your junk folder.');
                    history.push('/member-login');
                } else {
                    alert(result.message);
                }
            }
            catch (ex) {                
                alert('Error...');
            }
            setIsLoaded(false);            
        } else {
            alert('Captcha does not match.');
            setCaptchaText('');
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6 text-right">
                            <CMSMenu />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-center">                            
                        </div>
                        <div class="col-md-6">
                            <div className="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                <div className="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Forgot Password</h3>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" className="form-control" value={credentials.email} onChange={(e) => handleChange(e, "email")} name="email" />
                                </div>
                                <div className="form-group">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <input type="text" className="form-control" placeholder="Enter Captcha Value" value={captchaText} onChange={e => setCaptchaText(e.target.value)}></input>
                                        </div>
                                        <div class="col-md-5" id="captcha" style={{ paddingTop: '6px' }}>
                                            <LoadCanvasTemplate reloadText="<i class='fa fa-refresh' style='font-size: 20px; margin-top: 4px;'></i>" />
                                            {/* <LoadCanvasTemplateNoReload /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group" style={{position: "relative"}}>
                                    <button className="btn btn-block btn-primary" onClick={handleSubmit}>Submit</button>
                                    {isLoaded &&
                                        <p class="loader"><img src='/assets/img/loading.gif' alt='' style={{ width: '100%' }} /></p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ForgotPassword);