import React, { useState, useEffect } from 'react';
import TableRows from "./TableRows"

function AddDeleteTableRows(props) {
    // const [rowsData, setRowsData] = useState([{
    //     mecid: 0,
    //     memberID: 0,
    //     name: '',
    //     phone: '',
    //     email: '',
    //     relation: '',
    //     address: ''
    // }]);
    const [rowsData, setRowsData] = useState(props.currentEmergencyContacts);   // initialize rowsData from Update Profile    

    const addTableRows = () => {
        if (rowsData.length < 10) {
            const rowsInput = {
                mecid: 0,
                memberID: 0,
                name: '',
                phone: '',
                email: '',
                relation: '',
                address: ''
            }
            setRowsData([...rowsData, rowsInput])
        } else {
            alert('Maximum 10 emergency contacts are allowed');
        }
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);   
    }    

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }
    
    props.getEmergencyContacts(rowsData);   // called from Update Profile
    
    return (
        <div>
            <table width="100%">
                <tbody>
                    <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
                    <button className="btnAdd" onClick={addTableRows}>Add</button>
                </tbody>
            </table>
        </div>
    )
}
export default AddDeleteTableRows