import axios from "axios";

export async function Fetch(endpoint, type = "get", body = "") {
  let axiosconfig = {
    method: type,
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}` + endpoint,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    data: body ? JSON.stringify(body) : ""
  };
  return new Promise((resolve, reject) => {
    axios(axiosconfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
        reject(error);
      });
  });
}

export async function FetchFileUpload(endpoint, type = "get", body = "") {
  let axiosconfig = {
    method: type,
    url: `${process.env.REACT_APP_BACKEND_BASE_URL}` + endpoint,    
    data: body
  };
  return new Promise((resolve, reject) => {
    axios(axiosconfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
        reject(error);
      });
  });
  // axios.post(url, data, {})
  //   .then(res => {
  //     console.warn(res);
  // })  
}