import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import CMSMenu from './CMSMenu';

function FireRegistration({ history }) {
    const location = useLocation();
    const [memberData, setMemberData] = useState({
        memberID: 0,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        phone: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        latitude: 0,
        longitude: 0,
        homeType: null,
        multiLevel: false,
        levelNumber: null,
        fastestAccess: null,
        roomNumber: null,
        bestAccessToEnter: null,
        referredBy: null,
        buildingType: null,
        constructionType: null,
        numberOfStories: null,
        pets: [],
        emergencyContacts: []
    });
    // const buildingtype = [
    //     { Id: 1, Type: 'Apartment' },
    //     { Id: 2, Type: 'Condo' },
    //     { Id: 3, Type: 'House' },
    //     { Id: 4, Type: 'Mobile Home' },
    //     { Id: 5, Type: 'Office' },
    //     { Id: 6, Type: 'School' },
    //     { Id: 7, Type: 'Shopping Center' },
    //     { Id: 8, Type: 'Townhouse' }
    // ];
    const constructiontype = [
        { Id: 1, Type: 'Brick' },
        { Id: 2, Type: 'Concrete' },
        { Id: 3, Type: 'Stucco' },
        { Id: 4, Type: 'Wood frame' }
    ];
    const numberofstories = [
        { Id: 1, Type: '1' },
        { Id: 2, Type: '2' },
        { Id: 3, Type: '3' },
        { Id: 4, Type: 'More' }
    ];
    // const buildingtypefields = { text: 'Type', value: 'Type' };
    const constructiontypefields = { text: 'Type', value: 'Type' };
    const numberofstoriesfields = { text: 'Type', value: 'Type' };

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-registration');
        } else {
            memberData.email = location.state.email;
            memberData.password = location.state.password;
            memberData.firstName = location.state.firstName;
            memberData.lastName = location.state.lastName;
            memberData.dateOfBirth = location.state.dateOfBirth;
            memberData.phone = location.state.phone;
            memberData.address = location.state.address;
            memberData.city = location.state.city;
            memberData.state = location.state.state;
            memberData.zip = location.state.zip;
            memberData.homeType = location.state.homeType;
            memberData.multiLevel = location.state.multiLevel;
            memberData.levelNumber = location.state.levelNumber;
            memberData.fastestAccess = location.state.fastestAccess;
            memberData.roomNumber = location.state.roomNumber;
            // memberData.bestAccessToEnter = location.state.bestAccessToEnter;
            memberData.referredBy = location.state.referredBy;
            memberData.pets = location.state.pets;
            memberData.emergencyContacts = location.state.emergencyContacts;
        }
    }, []);

    const handleChange = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value });
    };
    const handleProfileFireNext = () => {
        history.push({
            pathname: '/medical-registration',
            state: memberData
        });
    }
    const handleProfileFireSkip = () => {
        history.push({
            pathname: '/medical-registration',
            state: memberData
        });
    }

    return (
        <div>
            <div style={{ float: "left" }}>
                <div class="int-container desktop">
                    <img src="/assets/img/int-bg.jpg" alt="" />
                </div>
                <div class="int-container mobile">
                    <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
                </div>
                <div class="main-container">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6">
                            </div>
                            <div class="col-md-6 text-right">
                                <CMSMenu />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-5 text-center">
                            </div>
                            <div class="col-md-7">
                                <div class="form-container">
                                    <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                    <div class="form-group">
                                        <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Fire</h3>
                                    </div>
                                    <div class="row">
                                        {/* <div class="col-md-4 form-group">
                                            <label>Building Type</label>
                                            <DropDownListComponent
                                                cssClass="e-outline input-border"
                                                dataSource={buildingtype}
                                                fields={buildingtypefields}
                                                value={memberData.buildingType}
                                                onChange={(e) => handleChange(e, "buildingType")}
                                                placeholder="Choose One"
                                            />
                                        </div> */}
                                        <div class="col-md-6 form-group">
                                            <label>Construction Type</label>
                                            <DropDownListComponent
                                                cssClass="e-outline input-border"
                                                dataSource={constructiontype}
                                                fields={constructiontypefields}
                                                value={memberData.constructionType}
                                                onChange={(e) => handleChange(e, "constructionType")}
                                                placeholder="Choose One"
                                            />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label>Number of Stories</label>
                                            <DropDownListComponent
                                                cssClass="e-outline input-border"
                                                dataSource={numberofstories}
                                                fields={numberofstoriesfields}
                                                value={memberData.numberOfStories}
                                                onChange={(e) => handleChange(e, "numberOfStories")}
                                                placeholder="Choose One"
                                            />
                                        </div>
                                        {/* <div class="col-md-6 form-group">
                                            <button className="btn btn-block btn-primary" onClick={handleProfileFireSkip}>Skip</button>
                                        </div> */}
                                        <div class="col-md-12 form-group">
                                            <button className="btn btn-block btn-primary" onClick={handleProfileFireNext}>Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FireRegistration);