import React from 'react';
import Map from './Map';

const LocationMap = (props) => {
    return (
        <div style={{ padding: '20px' }}>
            <div>
                <Map
                    google={props.google}
                    center={{ lat: 39.4995948, lng: -119.7702838 }}
                    height='300px'
                    zoom={15}
                />
            </div>
        </div>
    );
}

export default LocationMap