import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import Geocode from "react-geocode";

Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`);
Geocode.enableDebug();
const Api_Key = process.env.REACT_APP_GOOGLE_API_KEY;

class MapEmergency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            }
        }
    }

    componentDidMount() {
        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            response => {
                const address = response.results[0].formatted_address;

                this.setState({
                    address: (address) ? address : ''
                })
            },
            error => {
                console.error(error);
            }
        );
    };

    onInfoWindowClose = (event) => { };

    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap //google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    >
                        <Marker //google={this.props.google}
                            draggable={false}
                            position={{ lat: this.state.markerPosition.lat, lng: this.state.markerPosition.lng }}
                        >
                            {/* InfoWindow on top of marker */}
                            <InfoWindow onClose={this.onInfoWindowClose}>
                                <span style={{ padding: 0, margin: 0, color: 'black' }}>{this.state.address}</span>
                            </InfoWindow>
                        </Marker>
                    </GoogleMap>
                )
            )
        );
        let map;
        if (this.props.center.lat !== undefined && this.props.center.lng !== undefined) {
            map = <div>
                <AsyncMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Api_Key}&libraries=places`}
                    loadingElement={
                        <div style={{ height: `100%` }} />
                    }
                    containerElement={
                        <div style={{ height: this.props.height }} />
                    }
                    mapElement={
                        <div style={{ height: `100%` }} />
                    }
                />
            </div>

        } else {
            map = <div style={{ height: this.props.height }} />
        }
        return (map)
    }
}

export default MapEmergency