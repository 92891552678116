import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import EmergencyContact from './EmergencyContact';
import EmergencyDetails from './EmergencyDetails';
import UpdateProfile from './UpdateProfile';
import HomePlan from './HomePlan';
import ChangePassword from './ChangePassword';
import PaymentHistory from './PaymentHistory';
import MemberPayment from './MemberPayment';
import MemberCardInfo from './MemberCardInfo';
import Dashboard from './Dashboard';
import Error from '../Pages/Error';

function Member({ history, match, isLogin }) {
    useEffect(() => {
        if (!isLogin) {
            history.push('/member-login');
        }
    }, []);

    return (
        <div>
            <Switch>
                <Route exact path={`${match.path}`} component={Dashboard} />
                <Route path={`${match.path}/emergency-contact`} component={EmergencyContact} />
                <Route path={`${match.path}/emergency-details`} component={EmergencyDetails} />
                <Route path={`${match.path}/update-profile`} component={UpdateProfile} />
                <Route path={`${match.path}/home-plan`} component={HomePlan} />                
                <Route path={`${match.path}/member-payment`} component={MemberPayment} />
                <Route path={`${match.path}/member-card-info`} component={MemberCardInfo} />
                <Route path={`${match.path}/payment-history`} component={PaymentHistory} />
                <Route path={`${match.path}/change-password`} component={ChangePassword} />
                <Route component={Error} />
            </Switch>
        </div>
    )
}

const mapStateToProps = ({ login: { isLogin } }) => ({
    isLogin
})

export default connect(mapStateToProps)(withRouter(Member));