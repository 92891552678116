import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import AdminDashboard from './AdminDashboard';
import AdminLogin from './AdminLogin';
import ManageMembers from './ManageMembers';
import UpdateMember from './UpdateMember';
import TransactionsReport from './TransactionsReport';
import TransactionsMember from './TransactionsMember';
import ManageGiftCards from './ManageGiftCards';
import AddUpdateGiftCards from './AddUpdateGiftCards';
import GenerateGiftCards from './GenerateGiftCards';
import PrintGiftCards from './PrintGiftCards';
import ManageCountyEmergencyContact from './ManageCountyEmergencyContact';
import AddUpdateCountyEmergencyContact from './AddUpdateCountyEmergencyContact';
import AdminChangePassword from './AdminChangePassword';
import SendNotifications from './SendNotifications';
import MailEmergencyContacts from './MailEmergencyContacts';
import Error from '../../Pages/Error';

function Admin({ history, match, isLogin }) {
    // useEffect(() => {
    //     if (!isLogin) {            
    //         history.push('/admin/admin-login');
    //     }
    // }, []);

    return (
        <div>
            <Switch>
                <Route exact path={`${match.path}`} component={AdminDashboard} />
                <Route path={`${match.path}/admin-login`} component={AdminLogin} />                
                <Route path={`${match.path}/manage-members`} component={ManageMembers} />
                <Route path={`${match.path}/update-member`} component={UpdateMember} />                
                <Route path={`${match.path}/transactions-report`} component={TransactionsReport} />
                <Route path={`${match.path}/transactions-member`} component={TransactionsMember} />
                <Route path={`${match.path}/manage-gift-cards`} component={ManageGiftCards} />
                <Route path={`${match.path}/add-update-gift-cards`} component={AddUpdateGiftCards} />
                <Route path={`${match.path}/generate-gift-cards`} component={GenerateGiftCards} />
                <Route path={`${match.path}/print-gift-cards`} component={PrintGiftCards} />
                <Route path={`${match.path}/manage-county-emergency-contact`} component={ManageCountyEmergencyContact} />
                <Route path={`${match.path}/add-update-county-emergency-contact`} component={AddUpdateCountyEmergencyContact} />
                <Route path={`${match.path}/admin-change-password`} component={AdminChangePassword} />
                <Route path={`${match.path}/send-notifications`} component={SendNotifications} />
                <Route path={`${match.path}/mail-emergency-contacts`} component={MailEmergencyContacts} />
                <Route component={Error} />
            </Switch>
        </div>
    )
}

const mapStateToProps = ({ adminLogin: { isLogin } }) => ({
    isLogin
})

export default connect(mapStateToProps)(withRouter(Admin));