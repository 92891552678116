import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import MenuMember from './MenuMember';
import { Fetch } from "../services/dataservice";
import { connect } from 'react-redux';
import { reset } from "../redux/action/login.action";

function ChangePassword({ history, memberInfo, clearState }) {
    const memberId = parseInt(memberInfo.map((item) => item.mId));
    const location = useLocation();

    const [isMenu, setIsMenu] = useState(false);
    const [credentials, setCredentials] = useState({
        memberID: memberId,
        password: null,
        newPassword: null,
        confNewPassword: null
    });

    const [passwordShown, setPasswordShown] = useState({
        pwd: false,
        newPwd: false,
        confPwd: false
    });
    const [iconClass, setIconClass] = useState({
        pwd: 'fa fa-eye-slash eyestyle',
        newPwd: 'fa fa-eye-slash eyestyle',
        confPwd: 'fa fa-eye-slash eyestyle'
    });    
    const togglePasswordVisiblity = (str) => {
        if (str === 'psd') {            
            if (!passwordShown.pwd) {                
                setPasswordShown({ ...passwordShown, pwd: true });
                setIconClass({ ...iconClass, pwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, pwd: false });
            setIconClass({ ...iconClass, pwd: 'fa fa-eye-slash eyestyle' });
        } else if (str === 'newpsd') {                    
            if (!passwordShown.newPwd) {                
                setPasswordShown({ ...passwordShown, newPwd: true });
                setIconClass({ ...iconClass, newPwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, newPwd: false });
            setIconClass({ ...iconClass, newPwd: 'fa fa-eye-slash eyestyle' });
        } else if (str === 'confpsd') {                    
            if (!passwordShown.confPwd) {                
                setPasswordShown({ ...passwordShown, confPwd: true });
                setIconClass({ ...iconClass, confPwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, confPwd: false });
            setIconClass({ ...iconClass, confPwd: 'fa fa-eye-slash eyestyle' });
        }                
    };

    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    useEffect(() => {
        if (location.state === undefined) {
            setIsMenu(true);
        } else {
            // let requestChangePassword = location.state;
            setIsMenu(false);
        }
    }, []);

    const handleChangePassword = async () => {
        try {
            if (credentials.password !== credentials.newPassword) {
                if (credentials.newPassword !== credentials.confNewPassword) {
                    alert("New password & confirmation password don't match.");
                } else {
                    let result = await Fetch(`/Member/UpdatePassword`, "put", credentials);
                    if (result > 0) {
                        clearState();
                        history.push('/member-login');
                        alert('Password updated successfully.');
                    } else {
                        alert("Old password didn't match.");
                    }
                }
            } else {
                alert("New password can’t be the same as your old password.");
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            {isMenu &&
                                <div class="profile-menu">
                                    <MenuMember />
                                </div>
                            }
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Change Password</h3>
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>Old Password</label>
                                    <input type={passwordShown.pwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "password")} />
                                    <i class={iconClass.pwd} onClick={() => togglePasswordVisiblity('psd')}></i> 
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>New Password</label>
                                    <input type={passwordShown.newPwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "newPassword")} />
                                    <i class={iconClass.newPwd} onClick={() => togglePasswordVisiblity('newpsd')}></i> 
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>Confirm New Password</label>
                                    <input type={passwordShown.confPwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "confNewPassword")} />
                                    <i class={iconClass.confPwd} onClick={() => togglePasswordVisiblity('confpsd')}></i> 
                                </div>
                                <div class="form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleChangePassword}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    clearState: () => dispatch(reset())
})

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword));