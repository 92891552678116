export const signin = status => ({
    type: 'STATUS',
    payload: status
});

export const admin = info => ({
    type: 'EXIST',
    payload: info
});

export const reset = () => ({
    type: 'LOGOUT'
});