import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Fetch } from "../services/dataservice";

function VerifyRequest() {
    const { req } = window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = e.split('=')[1], r), {});
    const [verifyMessage, setVerifyMessage] = useState('');
    const [verifyPassword, setVerifyPassword] = useState({
        memberID: req
    });

    useEffect(() => {
        try {
            (async () => {                
                if (verifyPassword.memberID) {
                    let result = await Fetch(`/Member/UpdateVerifyPassword`, "put", verifyPassword);                
                    if (result > 0) {                                                  
                        setVerifyMessage('Successfully reset your Sign-In password.');
                    } else {
                        setVerifyMessage('Already verified.');
                    }
                }                
            })();

            return () => {
                // this now gets called when the component unmounts            
            };            
        }
        catch (ex) {
            alert('Error...');
        }
    }, []);

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            <div className="form-container">
                                <div class="form-group text-center">
                                    <br />
                                    <i class="fa fa-check" aria-hidden="true" style={{ fontSize: '40px', color: 'green' }}></i>
                                    <h5 style={{ color: "#fff", width: "100%", textAlign: "center", textTransform: 'none', fontWeight: 'normal' }}>
                                        {verifyMessage}
                                    </h5>
                                    <br />
                                    <span style={{ fontSize: '16px', color: '#989797' }}><Link to="/member-login">Sign-In</Link> to My Emergency Network</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyRequest;