import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import axios from "axios";
import { Fetch, FetchFileUpload } from "../services/dataservice";
import { connect } from 'react-redux';
import MenuMember from './MenuMember';
import Modal from 'react-modal';

Modal.setAppElement('#root');

function EmergencyContact({ memberInfo }) {
    // create a ref for the file input
    const inputRefImg1 = useRef(null);  
    const inputRefImg2 = useRef(null);

    const memberId = parseInt(memberInfo.map((item) => item.mId));
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [radioEmergencyValue, setRadioEmergencyValue] = useState('Fire');
    const [memberData, setMemberData] = useState({});
    const [memFullAddress, setMemFullAddress] = useState();
    const [memName, setMemName] = useState();
    const [fileImg1, setFileImg1] = useState(null);
    const [fileImg2, setFileImg2] = useState(null);
    const [emergencyFire, setEmergencyFire] = useState({
        memberID: memberId,
        emergencyType: 'FIRE',
        specificLocationOfFire: '',
        storyType: '',
        fireStarted: '',
        peopleAtLocation: '',
        removedToSafety: '',
        fireInjuries: '',
        vehicleType: null,
        licensePlate: null,
        vehicleModel: null,
        vehicleColor: null,
        atHome: '',        
        image1Comments: null,
        image1FileName: null,
        image2Comments: null,
        image2FileName: null,
        additionalComments: null
    });
    const [emergencyMedical, setEmergencyMedical] = useState({
        memberID: memberId,
        emergencyType: 'MEDICAL',
        natureOfEmergencyMedical: '',
        injuredPersonState: '',
        injuredPersonAge: '',
        vehicleType: null,
        licensePlate: null,
        vehicleModel: null,
        vehicleColor: null,
        atHome: '',
        image1Comments: null,
        image1FileName: null,
        image2Comments: null,
        image2FileName: null,
        additionalComments: null
    });
    const [emergencyPolice, setEmergencyPolice] = useState({
        memberID: memberId,
        emergencyType: 'POLICE',
        natureOfEmergencyPolice: '',
        natureOfEmergencyPoliceNotes: '',
        requiredMedicalAid: '',
        weapons: '',
        vehicleType: null,
        licensePlate: null,
        vehicleModel: null,
        vehicleColor: null,
        atHome: '',
        image1Comments: null,
        image1FileName: null,
        image2Comments: null,
        image2FileName: null,
        additionalComments: null
    });
    const [emergencyCatastrophic, setEmergencyCatastrophic] = useState({
        memberID: memberId,
        emergencyType: 'CATASTROPHIC',
        natureOfEmergencyCatastrophic: '',
        natureOfEmergencyCatastrophicNotes: '',       
        vehicleType: null,
        licensePlate: null,
        vehicleModel: null,
        vehicleColor: null,
        atHome: '',
        image1Comments: null,
        image1FileName: null,
        image2Comments: null,
        image2FileName: null,
        additionalComments: null
    });
    const [emergencyComments, setEmergencyComments] = useState({        
        image1Comments: null,
        image1FileName: null,
        image2Comments: null,
        image2FileName: null,
        additionalComments: null
    });
    const [vehicleInfo, setVehicleInfo] = useState({        
        vehicleType: null,
        licensePlate: null,
        vehicleModel: null,
        vehicleColor: null
    });
    const [isAtHome, setIsAtHome] = useState([
        { Id: 1, home: 'Is At Home', homeStatus: false }
    ]);
    // const [memberHomePlan, setMemberHomePlan] = useState({
    //     mPlanID: 0,
    //     memberID: memberId,
    //     homePlan: null,
    //     evacuationPlan: null,
    //     assetsLocation: null,
    //     homePlanFiles: [],
    //     evacuationPlanFiles: [],
    //     assetsLocationFiles: []
    // });
    const [smsStatus, setSMSStatus] = useState();
    const [mailRequest, setMailRequest] = useState({
        toEmail: process.env.REACT_APP_EMERGENCY_EMAIL,
        subject: 'Emergency Help',
        body: '',
        attachments: []
    });
    const [smsRequest, setSMSRequest] = useState({
        phoneNumber: process.env.REACT_APP_EMERGENCY_SMS_PHONE,        
        body: 'Hello, this is a sample text from Plivo!'
    });    

    useEffect(() => {        
        if (memberId > 0) {
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetById/` + memberId)
                .then((response) => {
                    setMemberData(response.data);
                    setMemFullAddress(response.data.address + ', ' + response.data.city + ', ' + response.data.state + ', ' + response.data.zip);
                    setMemName(response.data.firstName + ' ' + response.data.lastName);
                });
        }        
    }, []);

    const handleRadioEmergencyChange = e => {
        setRadioEmergencyValue(e.value);
        emergencyPolice.natureOfEmergencyPolice = '';
        emergencyCatastrophic.natureOfEmergencyCatastrophic = '';
    };
    const handleChangeFire = (e, property) => {
        setEmergencyFire({ ...emergencyFire, [property]: e.target.value });
    };
    const handleChangeMedical = (e, property) => {
        setEmergencyMedical({ ...emergencyMedical, [property]: e.target.value });
    };
    const handleChangePolice = (e, property) => {
        setEmergencyPolice({ ...emergencyPolice, [property]: e.target.value });
    };
    const handleChangeCatastrophic = (e, property) => {
        setEmergencyCatastrophic({ ...emergencyCatastrophic, [property]: e.target.value });
    };
    const handleChangeComments = (e, property) => {
        setEmergencyComments({ ...emergencyComments, [property]: e.target.value });
    };
    const handleChangeVehicleInfo = (e, property) => {
        setVehicleInfo({ ...vehicleInfo, [property]: e.target.value });
    };
    const handleFileChangeImg1 = e => {
        setFileImg1(e.target.files[0]);
    };
    const handleFileChangeImg2 = e => {
        setFileImg2(e.target.files[0]);
    };

    const handleClose = () => setModalIsOpen(false);
    
    // reset file input value
    const resetFileInput = () => {        
        inputRefImg1.current.value = null;
        inputRefImg2.current.value = null;
        setFileImg1(null);
        setFileImg2(null);
        emergencyComments.image1FileName = null;
        emergencyComments.image2FileName = null;
    };

    const handleSendMessage = async () => {
        try {
            setModalIsOpen(true);            
            let callResult = null;

            if (fileImg1) {
                const formData = new FormData();
                formData.append('file', fileImg1, fileImg1.name);   //the name 'file' must match the name of the parameter in the API controller's action                
                let result = await FetchFileUpload(`/File/Upload/4`, "post", formData);
                if (result.status == 'success') {
                    emergencyComments.image1FileName = result.uniqFileName;
                }
            }

            if (fileImg2) {
                const formData = new FormData();
                formData.append('file', fileImg2, fileImg2.name);   //the name 'file' must match the name of the parameter in the API controller's action                
                let result = await FetchFileUpload(`/File/Upload/4`, "post", formData);
                if (result.status == 'success') {
                    emergencyComments.image2FileName = result.uniqFileName;
                }
            }

            let presentAtHome = 'No';            
            isAtHome.forEach((data) => {
                if (data.homeStatus) {
                    presentAtHome = 'Yes';
                }
            })

            if (radioEmergencyValue.toLowerCase() === 'fire') {
                emergencyFire.vehicleType = vehicleInfo.vehicleType;
                emergencyFire.licensePlate = vehicleInfo.licensePlate;
                emergencyFire.vehicleModel = vehicleInfo.vehicleModel;
                emergencyFire.vehicleColor = vehicleInfo.vehicleColor;
                emergencyFire.atHome = presentAtHome;
                emergencyFire.image1Comments = emergencyComments.image1Comments;
                emergencyFire.image1FileName = emergencyComments.image1FileName;
                emergencyFire.image2Comments = emergencyComments.image2Comments;
                emergencyFire.image2FileName = emergencyComments.image2FileName;
                emergencyFire.additionalComments = emergencyComments.additionalComments;                
                callResult = await Fetch(`/EmergencyCalls/Add`, "post", emergencyFire);
            } else if (radioEmergencyValue.toLowerCase() === 'medical') {
                emergencyMedical.vehicleType = vehicleInfo.vehicleType;
                emergencyMedical.licensePlate = vehicleInfo.licensePlate;
                emergencyMedical.vehicleModel = vehicleInfo.vehicleModel;
                emergencyMedical.vehicleColor = vehicleInfo.vehicleColor;
                emergencyMedical.atHome = presentAtHome;
                emergencyMedical.image1Comments = emergencyComments.image1Comments;
                emergencyMedical.image1FileName = emergencyComments.image1FileName;
                emergencyMedical.image2Comments = emergencyComments.image2Comments;
                emergencyMedical.image2FileName = emergencyComments.image2FileName;
                emergencyMedical.additionalComments = emergencyComments.additionalComments;
                callResult = await Fetch(`/EmergencyCalls/Add`, "post", emergencyMedical);
            } else if (radioEmergencyValue.toLowerCase() === 'police') {
                emergencyPolice.vehicleType = vehicleInfo.vehicleType;
                emergencyPolice.licensePlate = vehicleInfo.licensePlate;
                emergencyPolice.vehicleModel = vehicleInfo.vehicleModel;
                emergencyPolice.vehicleColor = vehicleInfo.vehicleColor;
                emergencyPolice.atHome = presentAtHome;
                emergencyPolice.image1Comments = emergencyComments.image1Comments;
                emergencyPolice.image1FileName = emergencyComments.image1FileName;
                emergencyPolice.image2Comments = emergencyComments.image2Comments;
                emergencyPolice.image2FileName = emergencyComments.image2FileName;
                emergencyPolice.additionalComments = emergencyComments.additionalComments;
                callResult = await Fetch(`/EmergencyCalls/Add`, "post", emergencyPolice);
            } else if (radioEmergencyValue.toLowerCase() === 'catastrophic') {
                emergencyCatastrophic.vehicleType = vehicleInfo.vehicleType;
                emergencyCatastrophic.licensePlate = vehicleInfo.licensePlate;
                emergencyCatastrophic.vehicleModel = vehicleInfo.vehicleModel;
                emergencyCatastrophic.vehicleColor = vehicleInfo.vehicleColor;
                emergencyCatastrophic.atHome = presentAtHome;
                emergencyCatastrophic.image1Comments = emergencyComments.image1Comments;
                emergencyCatastrophic.image1FileName = emergencyComments.image1FileName;
                emergencyCatastrophic.image2Comments = emergencyComments.image2Comments;
                emergencyCatastrophic.image2FileName = emergencyComments.image2FileName;
                emergencyCatastrophic.additionalComments = emergencyComments.additionalComments;
                callResult = await Fetch(`/EmergencyCalls/Add`, "post", emergencyCatastrophic);
            }            
            
            if (memberId > 0 && callResult.callID > 0) {
                axios
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetHomePlanById/` + memberId)
                    .then((response) => {
                        if (response.data) {
                            sendMailAndMessage(response.data, callResult);
                        } else {
                            sendMailAndMessage('', callResult);
                        }
                    });
            }
        }
        catch (ex) {
            setModalIsOpen(false);
            alert('Error...');
        }

        resetFileInput();
    };

    const sendMailAndMessage = async (data, callResult) => {        
        let hpFiles = '';
        let epFiles = '';
        let alFiles = '';
        if (data) {        
            data.homePlanFiles.forEach((item, index) => {                                                                   
                hpFiles += '<a href="' + process.env.REACT_APP_IMAGEHOME_URL + item.fileName + '">' + item.fileName + '</a>';
                if (index < data.homePlanFiles.length - 1) {
                    hpFiles += ', ';
                }
            })            
            
            data.evacuationPlanFiles.forEach((item, index) => {                                                                   
                epFiles += '<a href="' + process.env.REACT_APP_IMAGEEVACUATION_URL + item.fileName + '">' + item.fileName + '</a>';
                if (index < data.evacuationPlanFiles.length - 1) {
                    epFiles += ', ';
                }                                 
            })

            data.assetsLocationFiles.forEach((item, index) => {                                                                   
                alFiles += '<a href="' + process.env.REACT_APP_IMAGEASSETS_URL + item.fileName + '">' + item.fileName + '</a>';
                if (index < data.assetsLocationFiles.length - 1) {
                    alFiles += ', ';
                }                                 
            })
        }
       
        let contentEmailHTML = '<div>' +
            '<p>Emergency: ' + radioEmergencyValue.toUpperCase() +
            '</p><p>Address: ' + memFullAddress +
            '</p><p><a href="#">Location in Maps</a>' +
            '</p><p>Name: ' + memName +
            '</p><p>Mobile: ' + memberData.phone +
            '</p><p><a href="' + process.env.REACT_APP_FRONTEND_BASE_URL + '/info-911?call=' + callResult.encryptCallID + '">Detail information</a>' +            
            '</p><p>Home Plan: ' + hpFiles +
            '</p><p>Evacuation Plan: ' + epFiles +
            '</p><p>Assets Location: ' + alFiles +
            // '</p><p>' + callResult.sms +                    
            '</p></div>';
        mailRequest.body = `<html><head><style></style></head><body>${contentEmailHTML}</body></html>`;
        let result = await Fetch(`/Email/Send`, "post", mailRequest);

        // For SMS
        let contentSMSHTML = 'Need emergency help on ' + radioEmergencyValue.toUpperCase() + 
            ', ' + memFullAddress +
            //', click for location ' + 
            ', ' + memName +
            ', ' + memberData.phone +
            ', click for detail information ' + process.env.REACT_APP_FRONTEND_BASE_URL + '/info-911?call=' + callResult.callID;            
        smsRequest.body = `${contentSMSHTML}`;
        //let result = await Fetch(`/SMSService/Send`, "post", smsRequest);
        //
        //72 yo male, unconscious and head wound, ambulance needed at 8823 Mortlake Lane 
        
        setIsLoaded(true);
        if (result.status === 'success') {            
            // alert('Email sent successfully.');            
            setSMSStatus('Message sent successfully.');
        } else {            
            setSMSStatus('Error on sending message.');
        }
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <div>
                                        <div class="form-group">
                                            <div class='form-group' style={{ color: "#fff", width: "100%", display: "block" }}>Type of emergency</div>
                                            <div>
                                                <div style={{ display: "block", width: "100%", marginBottom: "20px", float: "left" }}>
                                                    <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                        <RadioButtonComponent label='Fire' name='emergency' value='Fire' onChange={handleRadioEmergencyChange} checked='true' />
                                                    </div>
                                                    <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                        <RadioButtonComponent label='Medical' name='emergency' value='Medical' onChange={handleRadioEmergencyChange} />
                                                    </div>
                                                    <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                        <RadioButtonComponent label='Police' name='emergency' value='Police' onChange={handleRadioEmergencyChange} />
                                                    </div>
                                                    <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                        <RadioButtonComponent label='Catastrophic' name='emergency' value='Catastrophic' onChange={handleRadioEmergencyChange} />
                                                    </div>
                                                </div>
                                                {radioEmergencyValue.toLowerCase() === 'fire' &&
                                                    <div class="desc">
                                                        <div class="Fire e-box">
                                                            <div class="col-md-12 form-group">
                                                                <label>Specific location of the fire</label>
                                                                <TextBoxComponent cssClass="e-outline input-border" value={emergencyFire.specificLocationOfFire} onChange={(e) => handleChangeFire(e, "specificLocationOfFire")} />
                                                            </div>
                                                            <div class="col-md-12 form-group">
                                                                <label>Building/Story type</label>
                                                                <div class="row">
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='One Story' name='storytype' value='One Story' onChange={(e) => handleChangeFire(e, "storyType")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Multiple Story' name='storytype' value='Multiple Story' onChange={(e) => handleChangeFire(e, "storyType")} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 form-group">
                                                                <label>Fire started in</label>
                                                                <div class="row">
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Basement' name='firestarted' value='Basement' onChange={(e) => handleChangeFire(e, "fireStarted")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Garage Attached' name='firestarted' value='Garage Attached' onChange={(e) => handleChangeFire(e, "fireStarted")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Garage Detached' name='firestarted' value='Garage Detached' onChange={(e) => handleChangeFire(e, "fireStarted")} />
                                                                    </div>
                                                                </div>
                                                                <div class="row">                                                                    
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Kitchen' name='firestarted' value='Kitchen' onChange={(e) => handleChangeFire(e, "fireStarted")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Upstairs' name='firestarted' value='Upstairs' onChange={(e) => handleChangeFire(e, "fireStarted")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Downstairs' name='firestarted' value='Downstairs' onChange={(e) => handleChangeFire(e, "fireStarted")} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 form-group" style={{ display: 'inline-block' }}>
                                                                <label>How many people currently at this location</label>
                                                                <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                                    <RadioButtonComponent label='1' name='peoplecurrently' value='1' onChange={(e) => handleChangeFire(e, "peopleAtLocation")} />
                                                                </div>
                                                                <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                                    <RadioButtonComponent label='2' name='peoplecurrently' value='2' onChange={(e) => handleChangeFire(e, "peopleAtLocation")} />
                                                                </div>
                                                                <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                                    <RadioButtonComponent label='3' name='peoplecurrently' value='3' onChange={(e) => handleChangeFire(e, "peopleAtLocation")} />
                                                                </div>
                                                                <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                                    <RadioButtonComponent label='4' name='peoplecurrently' value='4' onChange={(e) => handleChangeFire(e, "peopleAtLocation")} />
                                                                </div>
                                                                <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                                    <RadioButtonComponent label='5' name='peoplecurrently' value='5' onChange={(e) => handleChangeFire(e, "peopleAtLocation")} />
                                                                </div>
                                                                <div className='form-group-new' style={{ float: 'left', marginRight: '40px' }}>
                                                                    <RadioButtonComponent label='More' name='peoplecurrently' value='More than 5' onChange={(e) => handleChangeFire(e, "peopleAtLocation")} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 form-group">
                                                                <label>Have you removed yourself/others to safety from this emergency</label>
                                                                <RadioButtonComponent label='Yes' name='removedtosafety' value='Yes' onChange={(e) => handleChangeFire(e, "removedToSafety")} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <RadioButtonComponent label='No' name='removedtosafety' value='No' onChange={(e) => handleChangeFire(e, "removedToSafety")} />
                                                            </div>
                                                            <div class="col-md-6 form-group">
                                                                <label>Injuries associated with this fire</label>
                                                                <RadioButtonComponent label='Yes' name='injuries' value='Yes' onChange={(e) => handleChangeFire(e, "fireInjuries")} />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <RadioButtonComponent label='No' name='injuries' value='No' onChange={(e) => handleChangeFire(e, "fireInjuries")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {radioEmergencyValue.toLowerCase() === 'medical' &&
                                                    <div class="desc">
                                                        <div class="Medical e-box">
                                                            <div class="col-md-12 form-group">
                                                                <label>Nature of emergency</label>
                                                                <div className='row'>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Cut' name='emergencynaturemedical' value='Cut' onChange={(e) => handleChangeMedical(e, "natureOfEmergencyMedical")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Fall' name='emergencynaturemedical' value='Fall' onChange={(e) => handleChangeMedical(e, "natureOfEmergencyMedical")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Heart Attack' name='emergencynaturemedical' value='Heart Attack' onChange={(e) => handleChangeMedical(e, "natureOfEmergencyMedical")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Poison' name='emergencynaturemedical' value='Poison' onChange={(e) => handleChangeMedical(e, "natureOfEmergencyMedical")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Puncture' name='emergencynaturemedical' value='Puncture' onChange={(e) => handleChangeMedical(e, "natureOfEmergencyMedical")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Stroke' name='emergencynaturemedical' value='Stroke' onChange={(e) => handleChangeMedical(e, "natureOfEmergencyMedical")} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 form-group">
                                                                <label>State of person injured</label>
                                                                <div class="col-md-12 padding0">
                                                                    <RadioButtonComponent label='Conscious' name='personinjured' value='Conscious' onChange={(e) => handleChangeMedical(e, "injuredPersonState")} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <RadioButtonComponent label='Unconscious' name='personinjured' value='Unconscious' onChange={(e) => handleChangeMedical(e, "injuredPersonState")} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 form-group">
                                                                <label>Age of injured person</label>
                                                                <TextBoxComponent cssClass="e-outline input-border" value={emergencyMedical.injuredPersonAge} onChange={(e) => handleChangeMedical(e, "injuredPersonAge")} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {radioEmergencyValue.toLowerCase() === 'police' &&
                                                    <div class="desc">
                                                        <div class="Police e-box">
                                                            <div class="col-md-12 form-group">
                                                                <label>Nature of emergency</label>
                                                                <div className='row'>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Abduction' name='emergencynaturepolice' value='Abduction' onChange={(e) => handleChangePolice(e, "natureOfEmergencyPolice")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Active Shooter' name='emergencynaturepolice' value='Active Shooter' onChange={(e) => handleChangePolice(e, "natureOfEmergencyPolice")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Home Invasion' name='emergencynaturepolice' value='Home Invasion' onChange={(e) => handleChangePolice(e, "natureOfEmergencyPolice")} />
                                                                    </div>
                                                                    <div class="col-md-12 form-group-new">
                                                                        <RadioButtonComponent label='Other' name='emergencynaturepolice' value='Other' onChange={(e) => handleChangePolice(e, "natureOfEmergencyPolice")} />
                                                                        {emergencyPolice.natureOfEmergencyPolice.toLowerCase() === 'other' &&                                                                            
                                                                            <div class="col-md-12 form-group" style={{ marginTop: '5px', paddingLeft: '30px' }}>                                                                                
                                                                                <TextBoxComponent cssClass="e-outline input-border" value={emergencyPolice.natureOfEmergencyPoliceNotes} onChange={(e) => handleChangePolice(e, "natureOfEmergencyPoliceNotes")} />
                                                                            </div>
                                                                        } 
                                                                    </div>                                                                                                                                       
                                                                </div>
                                                            </div>
                                                            <div class="col-md-5 form-group">
                                                                <label>Medical aid is required</label>
                                                                <div class="col-md-12 padding0">
                                                                    <RadioButtonComponent label='Yes' name='medicalaid' value='Yes' onChange={(e) => handleChangePolice(e, "requiredMedicalAid")} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <RadioButtonComponent label='No' name='medicalaid' value='No' onChange={(e) => handleChangePolice(e, "requiredMedicalAid")} />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-7 form-group">
                                                                <label>Weapon/weapons used</label>
                                                                <div class="col-md-12 padding0">
                                                                    <RadioButtonComponent label='Firearm' name='weapon' value='Firearm' onChange={(e) => handleChangePolice(e, "weapons")} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <RadioButtonComponent label='Knife' name='weapon' value='Knife' onChange={(e) => handleChangePolice(e, "weapons")} />
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <RadioButtonComponent label='Other' name='weapon' value='Other' onChange={(e) => handleChangePolice(e, "weapons")} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {radioEmergencyValue.toLowerCase() === 'catastrophic' &&
                                                    <div class="desc">
                                                        <div class="Catastrophic e-box">
                                                            <div class="col-md-12 form-group">
                                                                <label>Nature of emergency</label>
                                                                <div className='row'>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Earthquake' name='emergencynaturecatastrophic' value='Earthquake' onChange={(e) => handleChangeCatastrophic(e, "natureOfEmergencyCatastrophic")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Explosion' name='emergencynaturecatastrophic' value='Explosion' onChange={(e) => handleChangeCatastrophic(e, "natureOfEmergencyCatastrophic")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Tornado' name='emergencynaturecatastrophic' value='Tornado' onChange={(e) => handleChangeCatastrophic(e, "natureOfEmergencyCatastrophic")} />
                                                                    </div>
                                                                    <div class="col-md-4 form-group-new">
                                                                        <RadioButtonComponent label='Wildfire' name='emergencynaturecatastrophic' value='Wildfire' onChange={(e) => handleChangeCatastrophic(e, "natureOfEmergencyCatastrophic")} />
                                                                    </div>
                                                                    <div class="col-md-8 form-group-new">
                                                                        <RadioButtonComponent label='Other' name='emergencynaturecatastrophic' value='Other' onChange={(e) => handleChangeCatastrophic(e, "natureOfEmergencyCatastrophic")} />
                                                                        {emergencyCatastrophic.natureOfEmergencyCatastrophic.toLowerCase() === 'other' &&                                                                            
                                                                            <div class="col-md-12 form-group" style={{ marginTop: '5px', paddingLeft: '30px' }}>                                                                                
                                                                                <TextBoxComponent cssClass="e-outline input-border" value={emergencyCatastrophic.natureOfEmergencyCatastrophicNotes} onChange={(e) => handleChangeCatastrophic(e, "natureOfEmergencyCatastrophicNotes")} />
                                                                            </div> 
                                                                        }
                                                                    </div>                                                                                                                                      
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                    </div>
                                                }
                                                <div class="col-md-12 form-group" style={{ marginTop: '20px', padding: '0' }}>
                                                    <div className='row custom-file'>
                                                        <div class="col-md-12 form-group-new">
                                                            <label>Need help in</label>
                                                        </div>
                                                        <div class="col-md-12 form-group">
                                                            <RadioButtonComponent label='Auto' name='vehicle' value='Auto' onChange={(e) => handleChangeVehicleInfo(e, "vehicleType")} />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <RadioButtonComponent label='Boat' name='vehicle' value='Boat' onChange={(e) => handleChangeVehicleInfo(e, "vehicleType")} />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <RadioButtonComponent label='RV' name='vehicle' value='RV' onChange={(e) => handleChangeVehicleInfo(e, "vehicleType")} />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <RadioButtonComponent label='Truck' name='vehicle' value='Truck' onChange={(e) => handleChangeVehicleInfo(e, "vehicleType")} />
                                                        </div>
                                                        <div class="col-md-4 form-group">
                                                            <label>License plate</label>
                                                            <TextBoxComponent cssClass="e-outline input-border" value={vehicleInfo.licensePlate} onChange={(e) => handleChangeVehicleInfo(e, "licensePlate")} />
                                                        </div>
                                                        <div class="col-md-4 form-group">
                                                            <label>Vehicle Model</label>
                                                            <TextBoxComponent cssClass="e-outline input-border" value={vehicleInfo.vehicleModel} onChange={(e) => handleChangeVehicleInfo(e, "vehicleModel")} />
                                                        </div>
                                                        <div class="col-md-4 form-group">
                                                            <label>Vehicle Color</label>
                                                            <TextBoxComponent cssClass="e-outline input-border" value={vehicleInfo.vehicleColor} onChange={(e) => handleChangeVehicleInfo(e, "vehicleColor")} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 form-group" style={{ marginBottom: '20px', padding: '0' }}>                                                
                                                    {isAtHome.map((data, index) => (
                                                        <div key={index} style={{ width: '33%', marginBottom: '5px', float: 'left' }}>
                                                            <CheckBoxComponent checked={data.homeStatus} label={data.home} value=''
                                                                onChange={(e) => {
                                                                    data.homeStatus = e.target.checked;
                                                                    setIsAtHome([...isAtHome]);
                                                                }} />
                                                        </div>
                                                    ))}
                                                </div>
                                                <div class="col-md-12 form-group" style={{ padding: '0' }}>
                                                    <div className='row custom-file'>
                                                        <div class="col-md-12 form-group-new">
                                                            <label>You can upload images &amp; add comments</label>
                                                        </div>
                                                        <div class="col-md-12 form-group">
                                                            <label>Image-1 (optional)</label>
                                                            <textarea className='form-control' style={{ height: '50px' }} value={emergencyComments.image1Comments} onChange={(e) => handleChangeComments(e, "image1Comments")} />                                                            
                                                            <input ref={inputRefImg1} type="file" className="custom-file-input" style={{ display: 'inline-block' }} onChange={handleFileChangeImg1} />
                                                            <span style={{ lineHeight: '20px', float: 'right' }}>(only .jpg/.png/.pdf)</span>
                                                        </div>
                                                        <div class="col-md-12 form-group">
                                                            <label>Image-2 (optional)</label>
                                                            <textarea className='form-control' style={{ height: '50px' }} value={emergencyComments.image2Comments} onChange={(e) => handleChangeComments(e, "image2Comments")} />
                                                            <input ref={inputRefImg2} type="file" className="custom-file-input" style={{ display: 'inline-block' }} onChange={handleFileChangeImg2} />
                                                            <span style={{ lineHeight: '20px', float: 'right' }}>(only .jpg/.png/.pdf)</span>
                                                        </div>
                                                        <div class="col-md-12 form-group">
                                                            <label>Additional comments (optional)</label>
                                                            <textarea className='form-control' style={{ height: '70px' }} value={emergencyComments.additionalComments} onChange={(e) => handleChangeComments(e, "additionalComments")} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ display: "block", color: "#fff", width: "100%", float: "left", fontSize: "16px" }}>
                                                    Message will be sent to 911 operator and your emergency contacts.<br />
                                                    <button class="btn btn-block btn-primary" onClick={handleSendMessage}>Send Message</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal isOpen={modalIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto',
                                height: '330px',
                                fontSize: '20px'
                            }
                        }
                    }
                >
                    <div>
                        <button className="crossbtn" onClick={handleClose} title='Close'>X</button>
                    </div>
                    <div style={{ clear: 'both', margin: '25px 0', minHeight: '150px' }}>
                        {!isLoaded ? 
                            <p style={{ marginTop: '100px' }}><img src='/assets/img/loading.gif' alt='' style={{ width: '100%' }} /></p>                            
                            : 
                            (
                                <div>
                                    <p style={{ color: 'red' }}>{smsStatus}</p>
                                    <p style={{ color: '#000' }}>
                                        Emergency: <b>{radioEmergencyValue.toUpperCase()}</b><br />
                                        Address: <b>{memFullAddress}</b><br />
                                        Name: <b>{memName}</b><br />
                                        Mobile: <b>{memberData.phone}</b>
                                    </p>
                                    <p style={{ marginTop: '10px' }}>
                                        For more&nbsp;
                                        <Link
                                            to={{
                                                pathname: '/member/emergency-details',
                                                state: {
                                                    memInfo: memberData,
                                                    emrInfo:
                                                        (() => {
                                                            switch (radioEmergencyValue.toLowerCase()) {
                                                                case 'fire': return emergencyFire;
                                                                case 'medical': return emergencyMedical;
                                                                case 'police': return emergencyPolice;
                                                            }
                                                        })()
                                                }
                                            }}
                                            style={{ color: 'red' }}
                                        >information</Link>
                                    </p>
                                </div>
                            )
                        }                        
                    </div>
                </Modal>
            </div>
        </div>
    )
}

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps)(withRouter(EmergencyContact));