import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
// import { v4 as uuid } from 'uuid';
import axios from "axios";
import { Fetch, FetchFileUpload } from "../services/dataservice";
import { connect } from 'react-redux';
import MenuMember from './MenuMember';

const HomePlan = ({ memberInfo }) => {
    const memberId = parseInt(memberInfo.map((item) => item.mId));
    const [fileHome, setFileHome] = useState(null);
    const [fileEvacuation, setFileEvacuation] = useState(null);
    const [fileAssets, setFileAssets] = useState(null);
    const [selectedFileHome, setSelectedFileHome] = useState({ hpFileID: 0, memberID: memberId, fileName: '' });
    const [selectedFileEvacuation, setSelectedFileEvacuation] = useState({ epFileID: 0, memberID: memberId, fileName: '' });
    const [selectedFileAssets, setSelectedFileAssets] = useState({ alFileID: 0, memberID: memberId, fileName: '' });
    const [memberHomePlan, setMemberHomePlan] = useState({
        mPlanID: 0,
        memberID: memberId,
        homePlan: null,
        evacuationPlan: null,
        assetsLocation: null,
        homePlanFiles: [],
        evacuationPlanFiles: [],
        assetsLocationFiles: []
    });

    const handleChange = (e, property) => {
        setMemberHomePlan({ ...memberHomePlan, [property]: e.target.value });
    };
    const handleFileChangeHome = e => {
        setFileHome(e.target.files[0]);
    };
    const handleFileChangeEvacuation = e => {
        setFileEvacuation(e.target.files[0]);
    };
    const handleFileChangeAssets = e => {
        setFileAssets(e.target.files[0]);
    };

    useEffect(() => {
        fetchHomePlan();
    }, []);

    const fetchHomePlan = () => {
        if (memberId > 0) {
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetHomePlanById/` + memberId)
                .then((response) => {
                    if (response.data) {
                        setMemberHomePlan(response.data);
                    }
                });
        }
    };

    const handleSubmit = async () => {
        try {
            // const unique_id = uuid();
            // const small_id = unique_id.slice(0, 8);

            if (fileHome) {
                const formData = new FormData();
                // let flName = small_id + '_' + fileHome.name;
                formData.append('file', fileHome, fileHome.name);   //the name 'file' must match the name of the parameter in the API controller's action                
                let result = await FetchFileUpload(`/File/Upload/1`, "post", formData);
                if (result.status == 'success') {
                    selectedFileHome.fileName = result.uniqFileName;
                    let fid = await Fetch(`/Member/AddHomePlanFiles`, "post", selectedFileHome);
                }
            }

            if (fileEvacuation) {
                const formData = new FormData();                
                formData.append('file', fileEvacuation, fileEvacuation.name);   //the name 'file' must match the name of the parameter in the API controller's action                
                let result = await FetchFileUpload(`/File/Upload/2`, "post", formData);
                if (result.status == 'success') {
                    selectedFileEvacuation.fileName = result.uniqFileName;
                    let fid = await Fetch(`/Member/AddEvacuationPlanFiles`, "post", selectedFileEvacuation);
                }
            }

            if (fileAssets) {
                const formData = new FormData();                
                formData.append('file', fileAssets, fileAssets.name);   //the name 'file' must match the name of the parameter in the API controller's action                
                let result = await FetchFileUpload(`/File/Upload/3`, "post", formData);
                if (result.status == 'success') {
                    selectedFileAssets.fileName = result.uniqFileName;
                    let fid = await Fetch(`/Member/AddAssetsLocationFiles`, "post", selectedFileAssets);
                }
            }

            let res = 0;
            if (memberHomePlan.mPlanID > 0) {
                res = await Fetch(`/Member/UpdateHomePlan/` + memberHomePlan.mPlanID, "put", memberHomePlan);
            } else {
                res = await Fetch(`/Member/AddHomePlan`, "post", memberHomePlan);
                memberHomePlan.mPlanID = res;
            }

            if (res > 0) {
                alert('Updated successfully.');
            } else {
                alert('Error in updating record.');
            }

            fetchHomePlan();    //refresh page
        }
        catch (ex) {
            alert('Error...');
        }
    };

    const handleDeleteHomeFile = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete?')) {
                axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/DeleteHomePlanFile/` + id);                
                fetchHomePlan();
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };
    const handleDeleteEvacuationFile = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete?')) {
                axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/DeleteEvacuationPlanFile/` + id);                
                fetchHomePlan();                
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };
    const handleDeleteAssetsFile = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete?')) {
                axios.delete(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/DeleteAssetsLocationFile/` + id);                
                fetchHomePlan();                
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };

    return (
        <div>
             <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container" style={{ width: "100%" }}>
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Home Plan</h3>
                                </div>
                                <div className="form-group">
                                    <label>Home Plan Detail</label>
                                    <textarea className='form-control' style={{ height: '100px' }} value={memberHomePlan.homePlan} onChange={(e) => handleChange(e, "homePlan")} />
                                    <div class="custom-file">
                                        <input type="file" className="custom-file-input" onChange={handleFileChangeHome} /><span style={{ color: '#ccc' }}>(only .jpg/.png/.pdf)</span>
                                        <div style={{ marginTop: '10px' }}>
                                            {memberHomePlan.homePlanFiles.map((data, index) => (
                                                <div key={index} class='row'>
                                                    <div class="col-md-12 form-group-pet">
                                                        <button title="Delete" className="delbtn" onClick={() => handleDeleteHomeFile(data.hpFileID)}>X</button>&nbsp;&nbsp;
                                                        <Link style={{ color: '#ef5030' }} to={{ pathname: process.env.REACT_APP_IMAGEHOME_URL + data.fileName }} target='_blank'>{data.fileName}</Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <hr style={{ width: '30%' }} />
                                    <label>Evacuation Plan</label>
                                    <textarea className='form-control' style={{ height: '100px' }} value={memberHomePlan.evacuationPlan} onChange={(e) => handleChange(e, "evacuationPlan")} />
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" onChange={handleFileChangeEvacuation} /><span style={{ color: '#ccc' }}>(only .jpg/.png/.pdf)</span>
                                        <div style={{ marginTop: '10px' }}>
                                            {memberHomePlan.evacuationPlanFiles.map((data, index) => (
                                                <div key={index} class='row'>
                                                    <div class="col-md-12 form-group-pet">
                                                        <button title="Delete" className="delbtn" onClick={() => handleDeleteEvacuationFile(data.epFileID)}>X</button>&nbsp;&nbsp;
                                                        <Link style={{ color: '#ef5030' }} to={{ pathname: process.env.REACT_APP_IMAGEEVACUATION_URL + data.fileName }} target='_blank'>{data.fileName}</Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <hr style={{ width: '30%' }} />
                                    <label>Pre-determined location for important assets/document</label>
                                    <textarea className='form-control' style={{ height: '100px' }} value={memberHomePlan.assetsLocation} onChange={(e) => handleChange(e, "assetsLocation")} />
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" onChange={handleFileChangeAssets} /><span style={{ color: '#ccc' }}>(only .jpg/.png/.pdf)</span>
                                        <div style={{ marginTop: '10px' }}>
                                            {memberHomePlan.assetsLocationFiles.map((data, index) => (
                                                <div key={index} class='row'>
                                                    <div class="col-md-12 form-group-pet">
                                                        <button title="Delete" className="delbtn" onClick={() => handleDeleteAssetsFile(data.alFileID)}>X</button>&nbsp;&nbsp;
                                                        <Link style={{ color: '#ef5030' }} to={{ pathname: process.env.REACT_APP_IMAGEASSETS_URL + data.fileName }} target='_blank'>{data.fileName}</Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center">
                                    <button className="btn btn-block btn-primary" onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps)(withRouter(HomePlan));