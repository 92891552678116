import React from 'react';
import './CMSMenu.css';

function CMSMenu() {
    return (
        <div>
            <header class="menurow">            
                <div class="rd-navbar-wrap">
                    <nav class="rd-navbar rd-navbar-classic rd-navbar-original rd-navbar--is-stuck rd-navbar-static" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static" data-xxl-device-layout="rd-navbar-static" data-lg-stick-up-offset="46px" data-xl-stick-up-offset="46px" data-xxl-stick-up-offset="46px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
                        <div class="rd-navbar-main-outer">
                            <div class="rd-navbar-main">
                                <div class="nav-wrap">
                                    <div class="rd-navbar-panel" style={{ float: 'left' }}>
                                        <div class="rd-navbar-brand">
                                        </div>
                                        <div class="btn-menu">
                                            <span></span>
                                        </div>
                                    </div>
                                    <nav id="mainnav" class="mainnav">
                                        <ul class="menu">
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><a href="https://myemergencynetwork.com/Home.shtml" target='_blank'>Home</a></li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><a href="https://myemergencynetwork.com/How%20it%20works.shtml" target='_blank'>How it works</a>
                                                <ul class="submenu">
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-page"><a href="https://myemergencynetwork.com/Why%20We%20Built%20the%20App.shtml" target='_blank'>Why We Built the App</a></li>
                                                </ul>
                                            </li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><a href="https://myemergencynetwork.com/About%20Us.shtml" target='_blank'>About Us</a></li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><a href="https://myemergencynetwork.com/Non%20Emergency%20Links.shtml" target='_blank'>Non emergency links</a></li>
                                            <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children"><a href="https://novatechfs.com/Contact.aspx" target="_blank">Contact Us</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
}

export default CMSMenu;