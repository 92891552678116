import React from 'react';
import { Link } from 'react-router-dom';

function Error() {
    return (
        <div>
             <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            <div class="profile-menu">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px" }}></i></Link>
                            </div>
                            <div className="form-container" style={{ textAlign: 'center' }}><br /><br /><br /><br /><br />
                                <h2>404</h2>
                                <h2>Page not found</h2>
                                <br /><br /><br /><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error;