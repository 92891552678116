import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from "axios";
import CMSMenu from './CMSMenu';

function MemberLogin({ history }) {
    const [messageDiv, setMessageDiv] = useState(false);
    const [credentials, setCredentials] = useState({
        email: null
    });

    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    const handleContinue = async () => {
        try {
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetByEmail/` + credentials.email)
                .then((response) => {
                    if (response.data > 0) {
                        history.push({
                            pathname: '/signin',
                            state: credentials
                        });
                    } else {
                        setMessageDiv(true);
                    }
                });
        }
        catch (ex) {
            alert('Error...');
        }
    };

    return (
        <div>
             <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col-md-6 text-right">
                            <CMSMenu />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 text-center">
                        </div>
                        <div class="col-md-6">
                            {messageDiv &&
                                <div className='errorMsg'>
                                    <i class="fa fa-warning warn"></i>
                                    <span style={{ color: '#db0f16', fontSize: '16px' }}>There was a problem</span>
                                    <br />
                                    We cannot find an account with that email address
                                </div>
                            }
                            <div className="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                <div className="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Sign-In</h3>
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="email" onChange={(e) => handleChange(e, "email")} className="form-control" />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleContinue}>Continue</button>
                                </div>
                            </div>
                            {/* <div className="form-group text-center createAcc">
                                <p>New to 911Nova?</p>
                                <Link to="/member-registration" className='linkbtn'>Create 911Nova account</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberLogin);