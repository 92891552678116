import React from "react";
import MenuAdmin from './MenuAdmin';

function AdminDashboard() {
  return (
    <div>
      <div class="int-container desktop">
        <img src="/assets/img/int-bg.jpg" alt="" />
      </div>
      <div class="int-container mobile">
        <img src="assets/img/home-alert-4-mobile.jpg" alt="" />
      </div>
      <div class="main-container">
        <div class="container">
          <div class="row">
            <div class="col-md-5 text-center">
            </div>
            <div class="col-md-7">
              <div class="profile-menu">
                <MenuAdmin />
              </div>
              <div class="form-container" style={{ backgroundColor: "rgba(0, 0, 0, 0.8);" }}>
                <div class="row">
                  <div class="form-group">
                    <div className="col-md-8">
                      <h2 style={{ color: "#fff", width: "100%", marginTop: "20px" }}>Welcome</h2>
                      <h2 style={{ color: "#EF5030", width: "100%" }}>Admin</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;