import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import MenuAdmin from './MenuAdmin';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Inject, Page, Sort, Toolbar, Filter
} from "@syncfusion/ej2-react-grids";
import { DataUtil } from '@syncfusion/ej2-data';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

const ManageGiftCards = ({ history }) => {
    const [giftCardData, setGiftCardData] = useState([]);
    const [showChild, setShowChild] = useState(false);
    const [dropdata, setDropdata] = useState([]);
    let grid;

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/GiftCards/GetAllGiftCards`)
            .then((response) => {
                setGiftCardData(response.data);
                setDropdata(DataUtil.distinct(response.data, 'agentName'))
                setShowChild(true);
            });
    }, []);

    const handleAddGiftCard = () => {
        history.push({
            pathname: '/admin/add-update-gift-cards',
            state: 0
        });
    }

    const toolbarOptions = [
        "Search"
    ];
    const pageSettingsOptions = {
        pageCount: 3, pageSize: 100, pageSizes: ['100', '200', '300', '400', '500']
    };

    const gridTemplate = (props) => {
        let utz = props.gcUtilize;

        if (utz.toLowerCase() === 'yes') {
            return <i class="fa fa-check" aria-hidden="true" title='Yes' style={{ fontSize: '17px', color: 'green' }}></i>;
        } else {
            return <i class="fa fa-times" aria-hidden="true" title='No' style={{ fontSize: '17px', color: '#d53c1d' }}></i>;
        }
    };

    const filterTemplate = (props) => {
        // dropdata.push('All');    // Adds an element to the end of an array.
        // let uniqueData = [...new Set(dropdata)];         // Create collections of unique values.
        // let uniqueData = DataUtil.distinct(dropdata);    // Create collections of unique values.

        dropdata.unshift('All');    // Adds an element to the beginning of an array.
        // React renders components 2 times on page load only in the development environment. To avoid this, we can comment 
        // out the <React.StrictMode> tag in index.js file. StrictMode has many benefits for development.
        // So 'All' will appear 2 times in the development environment.

        // The enabled attributes will be added based on the column property.
        return (<DropDownListComponent id={props.column.field} enabled={props.column.allowFiltering} popupHeight='200px'
            placeholder='Select a value' dataSource={dropdata} change={onChange} />);
    }

    const onChange = (args) => {
        if (grid) {
            if (args.value === 'All') {
                grid.clearFiltering();
            } else {
                grid.filterByColumn('agentName', 'equal', args.value);
            }
        }
    }

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Gift Cards</h3>
                                </div>
                                <div class="form-group" >
                                    <button className="btn btn-primary" style={{ padding: "10px 20px", float: "right" }} onClick={handleAddGiftCard}>Add</button>
                                </div>
                                <div class="form-group" style={{ clear: 'both', marginTop: "5px", display: "inline-block" }}>
                                    <div id='grdAdmin'>
                                        {showChild &&
                                            <GridComponent ref={g => grid = g} dataSource={giftCardData} allowSorting={true} height='auto' allowFiltering={true}
                                                allowPaging={true} pageSettings={pageSettingsOptions} toolbar={toolbarOptions} >
                                                <ColumnsDirective>
                                                    <ColumnDirective field='gcid' headerText='Gift Card Id' width='70' visible={false} />
                                                    <ColumnDirective field='agentName' headerText='Agent' width='250' filterTemplate={filterTemplate} />
                                                    <ColumnDirective field='code' headerText='Code' width='150' allowFiltering={false} />
                                                    <ColumnDirective field='amount' headerText='Amount' format='C2' width='100' textAlign='Right' allowFiltering={false} />
                                                    <ColumnDirective field='gcStatus' headerText='Status' width='70' allowFiltering={false} />
                                                    <ColumnDirective field='gcUtilize' headerText='Utilize' width='70' visible={false} />
                                                    <ColumnDirective headerText='Utilize' width='70' textAlign='Center' template={gridTemplate} />
                                                    <ColumnDirective width='70'
                                                        template={({ gcid }) => (
                                                            <button
                                                                className='e-icons e-edit e-btn e-flat e-icon-btn'
                                                                title='Edit'
                                                                onClick={() =>
                                                                    history.push({
                                                                        pathname: '/admin/add-update-gift-cards',
                                                                        state: gcid
                                                                    })
                                                                }
                                                            ></button>
                                                        )}
                                                    />
                                                </ColumnsDirective>
                                                <Inject services={[Page, Sort, Toolbar, Filter]} />
                                            </GridComponent>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ManageGiftCards);