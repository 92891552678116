import React, { useState, useEffect } from 'react';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Inject, Page, Sort, Toolbar, ExcelExport
} from "@syncfusion/ej2-react-grids";
import MenuAdmin from './MenuAdmin';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const ManageMembers = ({ history }) => {
    const [memberData, setMemberData] = useState([]);
    const [memberTransaction, setMemberTransaction] = useState([]);
    const [memberName, setMemberName] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const formatOptions = { type: 'date', format: 'MM/dd/yyyy' };    

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetAllMemberGrid`)
            .then((response) => {
                setMemberData(response.data);
            });
    }, []);

    const toolbarOptions = [
        'Search', 'ExcelExport'
    ];
    const pageSettingsOptions = {
        pageCount: 3, pageSize: 25, pageSizes: ['All', '25', '50', '100', '200', '500']
    };
    const toolbarOptionsTrans = [
        'Search'
    ];
    const pageSettingsOptionsTrans = {
        pageCount: 3, pageSize: 5, pageSizes: ['5', '10', '20']
    };

    const handleClose = () => setModalIsOpen(false);

    const handleMemberTransactions = (memberID, firstName, lastName) => {
        try {
            setModalIsOpen(true);            
            setMemberName(firstName + ' ' + lastName);   
            axios
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Transactions/GetTransactionByMemberId/` + memberID)
                .then((response) => {
                    setMemberTransaction(response.data);
                });
            setIsLoaded(true);
        }
        catch (ex) {
            setModalIsOpen(false);
            alert('Error...');
        }
    }

    let grid;
    const toolbarClickExport = (args) => {
        if (grid && args.item.id === 'GridMember_excelexport') {
            grid.showSpinner();
            // grid.excelExport();
            const excelExportProperties = {
                fileName: 'Member.xlsx',
                // dataSource: memberData
                // includeHiddenColumn: true   //exporting all hidden columns                
            };
            grid.columns[8].visible = true;     //show or hide custom columns while exporting
            grid.excelExport(excelExportProperties);
        }
    };
    const excelExportCompleteStatus = () => {
        grid.hideSpinner();
        if (grid) {
            grid.columns[8].visible = false;    //hide or show custom columns while exporting    
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 ">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div className='form-container'>
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Members</h3>
                                </div>
                                <div id='grdAdmin'>
                                    <GridComponent id='GridMember' dataSource={memberData} allowSorting={true} height='auto' pageSettings={pageSettingsOptions} allowPaging={true}
                                        toolbar={toolbarOptions} allowExcelExport={true} toolbarClick={toolbarClickExport} excelExportComplete={excelExportCompleteStatus} ref={g => grid = g}>
                                        <ColumnsDirective>
                                            <ColumnDirective field='memberID' headerText='Member Id' width='70' textAlign='left' visible={false} />
                                            <ColumnDirective field='firstName' headerText='First Name' width='110' textAlign='left' />
                                            <ColumnDirective field='lastName' headerText='Last Name' width='110' textAlign='left' />
                                            {/* <ColumnDirective field='dateOfBirth' headerText='DOB' width='100' textAlign='left' type='datetime' format='MM/dd/yyyy' /> */}
                                            <ColumnDirective field='createDate' headerText='Regd. Date' width='110' textAlign='left' type='datetime' format={formatOptions} />
                                            <ColumnDirective field='phone' headerText='Mobile' width='100' textAlign='left' />
                                            <ColumnDirective field='email' headerText='Email' width='150' textAlign='left' />
                                            <ColumnDirective field='mStatus' headerText='Status' width='80' textAlign='left' />
                                            <ColumnDirective field='po' headerText='PO' width='70' textAlign='left' />
                                            <ColumnDirective field='referredBy' headerText='Ref. By' width='70' textAlign='left' visible={false} />
                                            <ColumnDirective width="60"
                                                template={({ memberID }) => (
                                                    <button
                                                        className="e-icons e-edit e-btn e-flat e-icon-btn"
                                                        title="Update Status"
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: '/admin/update-member',
                                                                state: memberID
                                                            })
                                                        }
                                                    ></button>
                                                )}
                                            />
                                            <ColumnDirective width="60"
                                                template={({ memberID }) => (
                                                    <button
                                                        className="iconadmin"
                                                        title="Calls"
                                                        onClick={() =>
                                                            history.push({                                                                
                                                                // pathname: '/admin/calls-member',
                                                                pathname: '/admin/manage-members',
                                                                state: memberID
                                                            })
                                                        }
                                                    ><i class="fa fa-phone" style={{ fontSize: '16px', color: '#b1afaf' }} /></button>
                                                )}
                                            />
                                            <ColumnDirective width="60"
                                                template={({ memberID, firstName, lastName }) => (
                                                    <button className="iconadmin" title="Transactions" onClick={() => handleMemberTransactions(memberID, firstName, lastName)}>
                                                        <i class="fa fa-usd" style={{ fontSize: '16px', color: '#b1afaf' }} />
                                                    </button>
                                                )}
                                            />
                                            {/* <ColumnDirective width="60"
                                                template={({ memberID, firstName, lastName }) => (
                                                    <button
                                                        className="iconadmin"
                                                        title="Transactions"
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: '/admin/transactions-member',
                                                                state: { mId: memberID, fName: firstName, lName: lastName }
                                                            })
                                                        }
                                                    >TXN</button>
                                                )}
                                            />  */}
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Toolbar, ExcelExport]} />
                                    </GridComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Modal id='AdminMemTxnModal' isOpen={modalIsOpen}
                    style={
                        {
                            overlay: {
                                background: 'rgba(0, 0, 0, .8)'
                            },
                            content: {
                                color: 'black',
                                margin: '30px auto',
                                height: '580px'
                            }
                        }
                    }                    
                >
                    <div>
                        <button className="crossbtn" onClick={handleClose} title='Close'>X</button>
                    </div>
                    <div className="modalcontent">
                        {!isLoaded ?
                            <p style={{ marginTop: '150px', marginLeft: '250px' }}><i class="fa fa-spinner fa-spin" style={{ fontSize: '60px', color: 'black' }}></i></p>
                            :
                            (
                                <div>
                                    <p style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>Transaction History</p><br />
                                    <div>
                                        <span style={{ color: '#d53c1d', lineHeight: '40px', fontSize: '17px' }}>{memberName}</span>
                                        <GridComponent dataSource={memberTransaction} allowSorting={true} height='auto'
                                            allowPaging={true} pageSettings={pageSettingsOptionsTrans} toolbar={toolbarOptionsTrans}>
                                            <ColumnsDirective>
                                                <ColumnDirective field='txnID' headerText='Txn Id' width='80' textAlign='left' visible={false} />
                                                <ColumnDirective field='txnDate' headerText='Transaction Date' width='100' textAlign='left' type='datetime' format='MM/dd/yyyy HH:mm' />
                                                <ColumnDirective field='txnExpiryDate' headerText='Plan Expiry Date' width='100' textAlign='left' type='datetime' format='MM/dd/yyyy HH:mm' />
                                                <ColumnDirective field='txnAmount' headerText='Amount' format="C2" width='60' textAlign='right' />
                                                <ColumnDirective field='code' headerText='Gift Code' width='80' textAlign='left' />
                                                <ColumnDirective field='gatewayTxnID' headerText='Payment ID' width='160' textAlign='left' />
                                                <ColumnDirective field='payStatus' headerText='Status' width='70' textAlign='left' />
                                            </ColumnsDirective>
                                            <Inject services={[Page, Sort, Toolbar]} />
                                        </GridComponent>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default withRouter(ManageMembers);