import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import InputMask from 'react-input-mask';
import AddDeleteTableRows from "../AddDeleteTableRows";
import axios from "axios";
import { Fetch } from "../../services/dataservice";
import MenuAdmin from './MenuAdmin';

const UpdateMember = ({ history }) => {
    const location = useLocation();
    const [allStates, setAllStates] = useState([]);
    const [date, setDate] = useState(new Date());
    const [promotional, setPromotional] = useState(false); 
    const [memberData, setMemberData] = useState({
        memberID: location.state,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        dateOfBirth: null,
        phone: null,
        address: null,
        city: null,
        state: null,
        zip: null,
        latitude: 0,
        longitude: 0,
        homeType: null,
        multiLevel: false,
        levelNumber: null,
        fastestAccess: null,
        roomNumber: null,
        bestAccessToEnter: null,
        buildingType: null,
        constructionType: null,
        numberOfStories: null,
        disabilities: false,
        healthConditions: null,
        careTakerPhone: null,
        careTakerName: null,
        caregiver: false,
        policeComment: null,
        referredBy: null,
        pets: [],
        emergencyContacts: [],
        status: false,
        promotionalOffers: false
    });
    const [petInfo, setPetInfo] = useState([
        { Id: 1, petMember: false, mPetID: 0, memberID: 0, petType: 'Bird', petNature: false, notes: null },
        { Id: 2, petMember: false, mPetID: 0, memberID: 0, petType: 'Cat', petNature: false, notes: null },
        { Id: 3, petMember: false, mPetID: 0, memberID: 0, petType: 'Dog', petNature: false, notes: null },
        { Id: 4, petMember: false, mPetID: 0, memberID: 0, petType: 'Ferret', petNature: false, notes: null },
        { Id: 5, petMember: false, mPetID: 0, memberID: 0, petType: 'Pocket Pet', petNature: false, notes: null },
        { Id: 6, petMember: false, mPetID: 0, memberID: 0, petType: 'Snake', petNature: false, notes: null },        
        { Id: 7, petMember: false, mPetID: 0, memberID: 0, petType: 'Other', petNature: false, notes: null }
    ]);    
    const [currentEContacts, setCurrentEContacts] = useState([
        { mecid: 0, memberID: 0, name: '', phone: '', email: '', relation: '', address: '' }
    ]);
    const hometype = [
        { Id: 1, Type: 'Apartment' },
        { Id: 2, Type: 'Condo' },
        { Id: 3, Type: 'High Rise' },
        { Id: 4, Type: 'House' },
        { Id: 5, Type: 'Mobile Home' },
        { Id: 6, Type: 'Office' },
        { Id: 7, Type: 'RV' },
        { Id: 8, Type: 'School' },
        { Id: 9, Type: 'Shopping Center' },
        { Id: 10, Type: 'Townhouse' }
    ];
    // const fastestaccess = [
    //     { Id: 1, Type: 'Inside' },
    //     { Id: 2, Type: 'Outside' },
    //     { Id: 3, Type: 'Stairs/Elevator' }
    // ];
    // const bestaccess = [
    //     { Id: 1, Type: 'Front Door' },
    //     { Id: 2, Type: 'Side Entrance Left' },
    //     { Id: 3, Type: 'Side Entrance Right' }
    // ];
    // const buildingtype = [
    //     { Id: 1, Type: 'Apartment' },
    //     { Id: 2, Type: 'Condo' },
    //     { Id: 3, Type: 'House' },
    //     { Id: 4, Type: 'Mobile Home' },
    //     { Id: 5, Type: 'Office' },
    //     { Id: 6, Type: 'School' },
    //     { Id: 7, Type: 'Shopping Center' },
    //     { Id: 8, Type: 'Townhouse' }
    // ];
    const constructiontype = [
        { Id: 1, Type: 'Brick' },
        { Id: 2, Type: 'Concrete' },
        { Id: 3, Type: 'Stucco' },
        { Id: 4, Type: 'Wood frame' }
    ];
    const numberofstories = [
        { Id: 1, Type: '1' },
        { Id: 2, Type: '2' },
        { Id: 3, Type: '3' },
        { Id: 4, Type: 'More' }
    ];
    const [healthInfo, setHealthInfo] = useState([
        { Id: 1, healthType: 'Diabetes', healthStatus: false },
        { Id: 2, healthType: 'Epilepsy', healthStatus: false },
        { Id: 3, healthType: 'Hypertension', healthStatus: false },
        { Id: 4, healthType: 'Non-ambulatory', healthStatus: false },
        { Id: 5, healthType: 'Oxygen', healthStatus: false },        
        { Id: 6, healthType: 'Wheelchair', healthStatus: false }
    ]);
    const memberstatus = [
        { Id: 1, Value: true, Type: 'Active' },
        { Id: 2, Value: false, Type: 'Inactive' }
    ];

    const statefields = { text: 'stateName', value: 'stateCode' };
    const hometypefields = { text: 'Type', value: 'Type' };
    // const fastestaccessfields = { text: 'Type', value: 'Type' };
    // const bestaccessfields = { text: 'Type', value: 'Type' };
    // const buildingtypefields = { text: 'Type', value: 'Type' };
    const constructiontypefields = { text: 'Type', value: 'Type' };
    const numberofstoriesfields = { text: 'Type', value: 'Type' };
    const memberstatusfields = { text: 'Type', value: 'Value' };

    const handleChange = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value });
    };
    const handleChangeUnmask = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value.replace(/[^0-9]+/g, '') });
    };
    const handleChangeBool = (e, property) => {
        setMemberData({ ...memberData, [property]: e.target.value.toLowerCase() === 'true' });
    };

    let eContacts = [];
    const handleEmergencyContacts = (emergContacts) => {
        eContacts = emergContacts;
    };

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/States/GetState`)
            .then((response) => {
                setAllStates(response.data);
            });

        if (memberData.memberID > 0) {
            // execute after 0.1 seconds for binding allStates before it
            const timer = setTimeout(() => {
                axios
                    .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetById/` + memberData.memberID)
                    .then((response) => {
                        setMemberData(response.data);
                        setDate(response.data.dateOfBirth)
                        setPromotional(response.data.promotionalOffers);

                        // for emergency contacts
                        let emrContacts = response.data.emergencyContacts;
                        if (emrContacts.length > 0) {
                            currentEContacts.shift();   // delete 1st record i.e, blank
                            emrContacts.forEach((item) => {
                                currentEContacts.push(item);
                            })
                            // setCurrentEContacts([...currentEContacts]);    // refresh emergency contacts                            
                        }

                        // for pets at location
                        let petsLocation = response.data.pets;
                        if (petsLocation.length > 0) {
                            petsLocation.forEach((item) => {
                                for (let obj of petInfo) {
                                    if (obj.petType === item.petType) {
                                        obj.memberID = item.memberID;
                                        obj.petMember = true;
                                        obj.petNature = item.petNature;
                                        obj.notes = item.notes;
                                        break;
                                    }
                                }
                            })
                            // setPetInfo([...petInfo]);    // refresh pets at location
                        }

                        // for health conditions
                        let hConditions = response.data.healthConditions;
                        if (hConditions) {
                            let subStr = hConditions.substring(0, hConditions.length - 1)
                            let hInfo = subStr.split(';');
                            hInfo.forEach((item) => {
                                for (let obj of healthInfo) {
                                    if (obj.healthType === item) {
                                        obj.healthStatus = true;
                                        break;
                                    }
                                }
                            })
                            // setHealthInfo([...healthInfo]);  // refresh health conditions
                        }
                        //                                                

                        setMemberData({ ...response.data });  // refresh page                        
                    });
            }, 100);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleUpdateProfile = async () => {
        try {
            axios
                .put(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/UpdateMemberByAdmin/${memberData.memberID}/${memberData.status}/${promotional}`)
                .then((response) => {
                    if (response.data > 0) {
                        history.push('/admin/manage-members');
                    } else {
                        alert('Error in updating record.');
                    }
                });
        }
        catch (ex) {
            alert('Error...');
        }
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '20px' }}>                                
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Update Member</h3>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>First Name</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.firstName} onChange={(e) => handleChange(e, "firstName")} readOnly="true" />
                                </div>
                                <div class="col-md-6 form-group">
                                    <label>Last Name</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.lastName} onChange={(e) => handleChange(e, "lastName")} readOnly="true" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Date of Birth</label>
                                    <DatePicker value={date} onChange={setDate} maxDate={new Date()} format='MM/dd/yyyy' closeCalendar={true} disabled={true} autoFocus={false}/>                                    
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Mobile</label>
                                    <InputMask className='textmasking' mask='(999) 999-9999' value={memberData.phone} onChange={(e) => handleChangeUnmask(e, "phone")} readOnly="true" />                                    
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Email</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.email} readOnly="true" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Address</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.address} onChange={(e) => handleChange(e, "address")} readOnly="true" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>City</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.city} onChange={(e) => handleChange(e, "city")} readOnly="true" />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>State</label>
                                    <DropDownListComponent
                                        id="ddlState"
                                        cssClass="e-outline input-border dropdowndisable"
                                        dataSource={allStates}
                                        fields={statefields}
                                        value={memberData.state}
                                        onChange={(e) => handleChange(e, "state")}
                                        placeholder="Select"
                                        popupHeight="250px"
                                    />
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Zip</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.zip} onChange={(e) => handleChange(e, "zip")} readOnly="true" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Building Type</label>
                                    <DropDownListComponent
                                        cssClass="e-outline input-border dropdowndisable"
                                        dataSource={hometype}
                                        fields={hometypefields}
                                        value={memberData.homeType}
                                        onChange={(e) => handleChange(e, "homeType")}
                                        placeholder="Choose One"
                                    />
                                </div>
                                <div class="col-md-12 form-group">                                    
                                    {(memberData.homeType === 'Apartment' || memberData.homeType === 'Townhouse') &&
                                        <div class="boxborder">
                                            <div class="col-md-12 form-group">
                                                Multi-level&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent label='Yes' name='multilevel' value='true' onChange={(e) => handleChangeBool(e, "multiLevel")} checked={memberData.multiLevel} disabled="true" />
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <RadioButtonComponent label='No' name='multilevel' value='false' onChange={(e) => handleChangeBool(e, "multiLevel")} checked={!memberData.multiLevel} disabled="true" />
                                            </div>
                                            {memberData.multiLevel &&
                                                <div class="col-md-12 form-group">
                                                    <label>Which level/floor</label>
                                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.levelNumber} onChange={(e) => handleChange(e, "levelNumber")} readOnly="true" />
                                                </div>
                                            }
                                        </div>                                        
                                    }
                                    {memberData.homeType === 'High Rise' &&
                                        <div class="boxborder">
                                            <div class="col-md-6 form-group">
                                                <label>Which level/floor</label>
                                                <TextBoxComponent cssClass="e-outline input-border" value={memberData.levelNumber} onChange={(e) => handleChange(e, "levelNumber")} readOnly="true" />
                                            </div>
                                            <div class="col-md-6 form-group">
                                                <label>Room #</label>
                                                <TextBoxComponent cssClass="e-outline input-border" value={memberData.roomNumber} onChange={(e) => handleChange(e, "roomNumber")} readOnly="true" /> 
                                            </div>
                                        </div>   
                                    }
                                    {/* <div class="col-md-12 form-group">
                                        <label>Fastest way to access emergency location</label>
                                        <DropDownListComponent
                                            cssClass="e-outline input-border dropdowndisable"
                                            dataSource={fastestaccess}
                                            fields={fastestaccessfields}
                                            value={memberData.fastestAccess}
                                            onChange={(e) => handleChange(e, "fastestAccess")}
                                            placeholder="Choose One"
                                        />
                                    </div> */}                                    
                                </div>
                                {/* {memberData.homeType === 'Townhouse' &&
                                    <div class="col-md-12 form-group">
                                        <div class="boxborder">
                                            <div class="col-md-12 form-group">
                                                <label>Best access to enter</label>
                                                <DropDownListComponent
                                                    cssClass="e-outline input-border dropdowndisable"
                                                    dataSource={bestaccess}
                                                    fields={bestaccessfields}
                                                    value={memberData.bestAccessToEnter}
                                                    onChange={(e) => handleChange(e, "bestAccessToEnter")}
                                                    placeholder="Choose One"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                } */}
                                <div class="col-md-12 form-group">
                                    <label>Emergency Contacts</label>
                                    <div class="boxborder inputdisable">
                                        <AddDeleteTableRows getEmergencyContacts={handleEmergencyContacts} currentEmergencyContacts={currentEContacts} />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label>Pets at location</label>
                                </div>
                                <div class="col-md-9">
                                    <label>Aggressive </label>
                                </div>
                                <div class="col-md-12 form-group">
                                    {petInfo.map((data, index) => (
                                        <div key={index} class='row'>
                                            <div class="col-md-3 form-group-pet">
                                                <CheckBoxComponent checked={data.petMember} label={data.petType} disabled="true"
                                                    onChange={(e) => {
                                                        data.petMember = e.target.checked;
                                                        setPetInfo([...petInfo]);
                                                    }} />
                                            </div>
                                            <div class="col-md-9 form-group-pet">
                                                {data.petType.toLowerCase() !== 'other' &&
                                                    <CheckBoxComponent checked={data.petNature} disabled="true"
                                                        onChange={(e) => {
                                                            data.petNature = e.target.checked;
                                                            setPetInfo([...petInfo]);
                                                        }} />
                                                }
                                                {data.petType.toLowerCase() === 'other' && data.petMember &&                                                        
                                                    <div class="col-md-12 form-group" style={{ marginTop: '5px', paddingLeft: '0' }}>                                                                                
                                                        <TextBoxComponent cssClass="e-outline input-border" value={data.notes} readOnly="true" 
                                                            onChange={(e) => {
                                                                data.notes = e.target.value;
                                                                setPetInfo([...petInfo]);
                                                            }} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div style={{ padding: "20px 0", display: 'inline-block' }}>
                                    <div class="form-group">
                                        <h4 style={{ color: "#fff", width: "100%", paddingLeft: '15px' }}>Fire</h4>
                                    </div>
                                    {/* <div class="col-md-4 form-group">
                                        <label>Building Type</label>
                                        <DropDownListComponent
                                            cssClass="e-outline input-border dropdowndisable"
                                            dataSource={buildingtype}
                                            fields={buildingtypefields}
                                            value={memberData.buildingType}
                                            onChange={(e) => handleChange(e, "buildingType")}
                                            placeholder="Choose One"
                                        />
                                    </div> */}
                                    <div class="col-md-6 form-group">
                                        <label>Construction Type</label>
                                        <DropDownListComponent
                                            cssClass="e-outline input-border dropdowndisable"
                                            dataSource={constructiontype}
                                            fields={constructiontypefields}
                                            value={memberData.constructionType}
                                            onChange={(e) => handleChange(e, "constructionType")}
                                            placeholder="Choose One"
                                        />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label>Number of Stories</label>
                                        <DropDownListComponent
                                            cssClass="e-outline input-border dropdowndisable"
                                            dataSource={numberofstories}
                                            fields={numberofstoriesfields}
                                            value={memberData.numberOfStories}
                                            onChange={(e) => handleChange(e, "numberOfStories")}
                                            placeholder="Choose One"
                                        />
                                    </div>
                                </div>
                                <div style={{ padding: "0 0 20px 0", display: 'inline-block' }}>
                                    <div class="form-group">
                                        <h4 style={{ color: "#fff", width: "100%", paddingLeft: '15px' }}>Medical</h4>
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label>Disabilities</label>
                                        <RadioButtonComponent label='Yes' name='disability' value='true' onChange={(e) => handleChangeBool(e, "disabilities")} checked={memberData.disabilities} disabled="true" />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <RadioButtonComponent label='No' name='disability' value='false' onChange={(e) => handleChangeBool(e, "disabilities")} checked={!memberData.disabilities} disabled="true" />
                                    </div>
                                    <div class="col-md-9 form-group">
                                        <label>Health Conditions</label>
                                        {healthInfo.map((data, index) => (
                                            <div key={index} style={{ width: '33%', marginBottom: '5px', float: 'left' }}>
                                                <CheckBoxComponent checked={data.healthStatus} label={data.healthType} value={data.healthType} disabled="true"
                                                    onChange={(e) => {
                                                        data.healthStatus = e.target.checked;
                                                        setHealthInfo([...healthInfo]);
                                                    }} />
                                            </div>
                                        ))}
                                    </div>
                                    <div class="col-md-3 form-group">
                                        <label>Caregiver</label>
                                        <RadioButtonComponent label='Yes' name='caregiver' value='true' onChange={(e) => handleChangeBool(e, "caregiver")} checked={memberData.caregiver} disabled="true" />
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <RadioButtonComponent label='No' name='caregiver' value='false' onChange={(e) => handleChangeBool(e, "caregiver")} checked={!memberData.caregiver} disabled="true" />
                                    </div>
                                    {memberData.caregiver === true &&
                                        <>
                                            <div class="col-md-4 form-group">
                                                <label>&nbsp;</label>
                                                <InputMask className='textmasking' mask='(999) 999-9999' placeholder="Mobile" value={memberData.careTakerPhone} onChange={(e) => handleChangeUnmask(e, "careTakerPhone")} readOnly="true" />
                                            </div>
                                            <div class="col-md-5 form-group">
                                                <label>&nbsp;</label>
                                                <TextBoxComponent cssClass="e-outline input-border" placeholder="Name" value={memberData.careTakerName} onChange={(e) => handleChange(e, "careTakerName")} readOnly="true" />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div style={{ padding: "0 0 20px 0" }}>
                                    <div class="form-group">
                                        <h4 style={{ color: "#fff", width: "100%", paddingLeft: '15px' }}>Police</h4>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <label>Comments</label>
                                        <textarea className='form-control' style={{ height: '70px' }} value={memberData.policeComment} onChange={(e) => handleChange(e, "policeComment")} readOnly="true" />
                                    </div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Referred By</label>
                                    <TextBoxComponent cssClass="e-outline input-border" value={memberData.referredBy} onChange={(e) => handleChange(e, "referredBy")} readOnly="true" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label>Status</label>
                                    <DropDownListComponent
                                        cssClass="e-outline input-border"
                                        dataSource={memberstatus}
                                        fields={memberstatusfields}
                                        value={memberData.status}
                                        onChange={(e) => handleChange(e, "status")}
                                    />
                                </div>
                                <div class="col-md-12 form-group" style={{ marginBottom: '15px' }}>
                                    <CheckBoxComponent checked={memberData.promotionalOffers} label='Promotional Offers' 
                                        onChange={(e) => {                                            
                                            setPromotional(e.target.checked);
                                        }} />                                       
                                </div>
                                <div class="col-md-12 form-group" >
                                    <button className="btn btn-block btn-primary" onClick={handleUpdateProfile}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(UpdateMember);