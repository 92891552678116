import { React } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <div class="credits col-md-12 text-center">                
                Copyright © 2024 My Emergency Network.
                <div class="page-scroll hidden-sm hidden-xs">
                    <a href="#page-top" class="back-to-top"><i class="fa fa-angle-up"></i></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;