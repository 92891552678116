import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = (props) => {
  const [value, setValue] = useState('');

  const toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    // ['blockquote', 'code-block'],    
    ['link', 'image', 'video'],                       // ['link', 'image', 'video', 'formula'],
  
    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values    
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],     // [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction
  
    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown    
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];

  const modules = {
    toolbar: toolbarOptions
  };

  props.getEditorContent(value);

  return <ReactQuill theme="snow" placeholder="enter your content...." modules={modules} value={value} onChange={setValue} />;
}

export default TextEditor;