import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import CardDetails from './CardDetails';

const MemberCardInfoNew = ({ history }) => {
    const location = useLocation();
    const [showChild, setShowChild] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState({
        amount: 0,
        paymentDescriptor: null,
        termPlan: 0,
        gcid: 0,
        memberID: 0
    });

    useEffect(() => {
        if (location.state === undefined) {
            history.push('/member-login');
        } else {
            paymentRequest.amount = location.state.amount;
            paymentRequest.paymentDescriptor = location.state.paymentDescriptor;
            paymentRequest.termPlan = location.state.termPlan;
            paymentRequest.gcid = location.state.gcid;
            paymentRequest.memberID = location.state.memberID;
            setShowChild(true);
        }
    }, []);

    const handleCancel = () => {
        history.push('/member-login');
    }

    return (
        <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                {showChild &&
                                    <CardDetails currentPayment={paymentRequest} />
                                }
                                <div class="col-md-12 form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberCardInfoNew);