import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify";
import axios from "axios";

function AboutUs() {
    const [pageContent, setPageContent] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/CMSPage/GetPageContentById/248`)
            .then((response) => {
                setPageContent(response.data);
            });
    }, []);
    
    const contentHTML = DOMPurify.sanitize(pageContent.pgS_T_PAGECONTENT);

    return (
        <div>
            <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
                <div class="main-container" style={{ padding: "50px 0 80px" }}>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-5">
                            </div>
                            <div class="col-md-7">
                                <div class="form-container" style={{ border: 'none' }}>
                                    <div class="text-center">
                                        <h3 style={{ color: '#fff' }}>About Us</h3>
                                    </div>
                                    <div style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: contentHTML }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="profile-menu" style={{ position: "absolute", top: "0" }}>
                <div class="container">
                    <Link to="/" style={{ color: "#f19f1f", fontSize: "18px" }}>Home</Link>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <Link to="/about-us" style={{ color: "#f19f1f", fontSize: "18px" }}>About Us</Link>
                </div>
            </div> */}
        </div>
    )
}

export default AboutUs;