import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import MenuAdmin from './MenuAdmin';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Inject, Page, Sort, Toolbar
} from "@syncfusion/ej2-react-grids";

const ManageCountyEmergencyContact = ({ history }) => {
    const [countyEmergencyData, setCountyEmergencyData] = useState([]);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/CountyEmergencyContact/GetAll`)
            .then((response) => {
                setCountyEmergencyData(response.data);
            });
    }, []);

    const handleAddCountyEmergencyContact = () => {
        history.push({
            pathname: '/admin/add-update-county-emergency-contact',
            state: 0
        });
    }

    const toolbarOptions = [
        "Search"
    ];
    const pageSettingsOptions = {
        pageCount: 3, pageSize: 100, pageSizes: ['100', '200', '300', '400', '500']
    };

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>County Emergency Contact</h3>
                                </div>
                                <div class="form-group" >
                                    <button className="btn btn-primary" style={{ padding: "10px 20px", float: "right" }} onClick={handleAddCountyEmergencyContact}>Add</button>
                                </div>
                                <div class="form-group" style={{ clear: 'both', marginTop: "5px", display: "inline-block" }}>
                                    <div id='grdAdmin'>
                                        <GridComponent dataSource={countyEmergencyData} allowSorting={true} height='auto'
                                            allowPaging={true} pageSettings={pageSettingsOptions} toolbar={toolbarOptions} >
                                            <ColumnsDirective>
                                                <ColumnDirective field='cecid' headerText='County Emergency Contact Id' width='70' textAlign='left' visible={false} />
                                                <ColumnDirective field='county' headerText='County' width='150' textAlign='left' />
                                                <ColumnDirective field='state' headerText='State' width='70' textAlign='left' />                                                 
                                                <ColumnDirective field='zip' headerText='Zip' width='80' textAlign='left' />                                                 
                                                <ColumnDirective field='phone' headerText='Phone' width='135' textAlign='left' />
                                                <ColumnDirective field='fax' headerText='Fax' width='135' textAlign='left' /> 
                                                <ColumnDirective field='email' headerText='Email' width='220' textAlign='left' />                                                                                             
                                                <ColumnDirective width="70"
                                                    template={({ cecid }) => (
                                                        <button
                                                            className="e-icons e-edit e-btn e-flat e-icon-btn"
                                                            title="Edit"
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: '/admin/add-update-county-emergency-contact',
                                                                    state: cecid
                                                                })
                                                            }
                                                        ></button>
                                                    )}
                                                />
                                            </ColumnsDirective>
                                            <Inject services={[Page, Sort, Toolbar]} />
                                        </GridComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ManageCountyEmergencyContact);