const initialState = {
    isLogin: false,
    adminInfo: []    
}

export const adminLoginReducer = (state = initialState, action) => {    
    switch(action.type){
        case 'STATUS':
            return { ...state, isLogin:action.payload };
        case 'EXIST':
            return { ...state, adminInfo:action.payload };
        case 'LOGOUT':
            return initialState;  //undefined;
        default:
            return state;
    }
}