import React, { useState, useEffect } from 'react';
import { useLocation, withRouter, Link } from 'react-router-dom';
import axios from "axios";
import MembershipSubscription from './MembershipSubscription';

const MemberPaymentNew = ({ history }) => {
    const location = useLocation();
    const { scr } = location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = e.split('=')[1], r), {});           
    
    const [showChild, setShowChild] = useState(false);
    const [memberData, setMemberData] = useState({
        memberID: 0,
        callFrom: 'registration'
    });

    useEffect(() => {
        try{
            if (location.state === undefined && scr === undefined) {            
                history.push('/member-login');
            } else {
                if (scr) {
                    axios                    
                        .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/VerifyForPayment/` + scr)
                        .then((response) => {
                            if (response.data > 0) {                                
                                memberData.memberID = response.data;
                                setShowChild(true);
                            } else {
                                alert('An error occurred. Please try again later.');
                                history.push('/member-login');
                            }
                        });                    
                } else if (location.state) {
                    memberData.memberID = location.state;
                    setShowChild(true);
                }
            }
        }
        catch (ex) {
            alert('An error occurred. Please try again later.');
            history.push('/member-login');
        }
    }, []);

    const handleLogin = () => {
        history.push('/member-login');
    }

    return (
        <div style={{ float: "left" }}>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="form-container">
                                <Link to="/"><i class="fa fa-home" style={{ color: "#f19f1f", fontSize: "20px", float: "right" }}></i></Link>
                                {showChild &&
                                    <MembershipSubscription currentMember={memberData} />
                                }
                                <div class="col-md-12 form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleLogin}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberPaymentNew);