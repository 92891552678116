import React from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import InputMask from 'react-input-mask';

function TableRows({ rowsData, deleteTableRows, handleChange }) {    
    const ddlRelation = [
        { Id: 1, Type: 'Caregiver' },
        { Id: 2, Type: 'Family member' },
        { Id: 3, Type: 'Friend' },
        { Id: 4, Type: 'Neighbor' }
    ];    
    const ddlRelationfields = { text: 'Type', value: 'Type' };

    return (
        rowsData.map((data, index) => {
            const { name, phone, email, relation, address } = data;
            return (
                <tr key={index} style={{ position: 'relative' }}>
                    <td>
                        <div className="col-sm-4 form-group">
                            <label>Name</label>
                            <TextBoxComponent cssClass="e-outline input-border" name="name" value={name} onChange={(evnt) => handleChange(index, evnt)} />
                            {/* <input type="text" value={name} onChange={(evnt) => (handleChange(index, evnt))} name="name" className="form-control" /> */}
                        </div>
                        <div className="col-sm-4 form-group">
                            <label>Mobile</label>
                            <InputMask className='textmasking' mask='(999) 999-9999' name="phone" value={phone} onChange={(evnt) => handleChange(index, evnt)} />
                            {/* <TextBoxComponent cssClass="e-outline input-border" name="phone" value={phone} onChange={(evnt) => handleChange(index, evnt)} /> */}
                        </div>
                        <div className="col-sm-4 form-group">
                            <label>Email</label>
                            <TextBoxComponent cssClass="e-outline input-border" name="email" value={email} onChange={(evnt) => handleChange(index, evnt)} />
                        </div>
                        <div className="col-sm-4 form-group">
                            <label>Relation</label>                                                        
                            <DropDownListComponent
                                cssClass="e-outline input-border"
                                dataSource={ddlRelation}
                                fields={ddlRelationfields}
                                name="relation"
                                value={relation}
                                onChange={(evnt) => handleChange(index, evnt)}
                                placeholder="Choose One"
                            />
                            {/* <select class="form-control" style={{color: '#282c34', padding: '0 7px'}} name="relation" value={relation} onChange={(evnt) => handleChange(index, evnt)}>
                                <option selected>Choose One</option>
                                {                                    
                                    ddlRelation.map(item => {
                                        return (
                                            <option key={item.Id} value={item.Type}>{item.Type}</option>
                                        )
                                    })
                                }
                            </select> */}
                        </div>
                        <div className="col-sm-8 form-group">
                            <label>Full Address</label>
                            <TextBoxComponent cssClass="e-outline input-border" name="address" value={address} onChange={(evnt) => handleChange(index, evnt)} />
                        </div>
                        {rowsData.length > 1 &&
                            <>
                                <button className="deletebtn" title="Delete" onClick={() => (deleteTableRows(index))}>X</button>
                                <div className="col-sm-12">
                                    <hr />
                                </div>
                            </>
                        }
                    </td>
                </tr>
            )
        })
    )
}

export default TableRows;