import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from "axios";
import { connect } from 'react-redux';
import { signin, reset } from "../redux/action/login.action";

function MenuMember({ history, loginStatus, clearState, memberInfo }) {
    const memberId = parseInt(memberInfo.map((item) => item.mId));
    const memberName = memberInfo.map((item) => item.fName);

    const [isAgeAbove18, setIsAgeAbove18] = useState(true);

    useEffect(() => {        
        // check for 18 years & status of promotional offers
        if (memberId) {
            axios                    
                .get(`${process.env.REACT_APP_BACKEND_BASE_URL}/Member/GetAge/` + memberId)
                .then((response) => {                    
                    if (response.data.age < 18 || response.data.promotionalOffers) {
                        setIsAgeAbove18(false);
                    }
                });                    
        }
    }, []);

    const handleLogout = () => {
        // loginStatus(false);
        clearState();
        history.push('/member-login');
    };

    return (
        <div>
            <div style={{ float: 'right' }}>
                <div class="cart">
                    <span className='memberName'><i class="fa fa-user" style={{ color: "#f19f1f" }}></i>&nbsp;&nbsp;Welcome {memberName}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i class="fa fa-bars" style={{ fontSize: '25px' }}></i>
                    <div id="sidebar">
                        <ul>
                            <li><Link to="/member">Dashboard</Link></li>
                            <li><Link to="/member/update-profile">Profile</Link></li>
                            {/* <li><Link to="/member/home-plan">Home Plan</Link></li> */}
                            <li><Link to="/member/payment-history">Payment History</Link></li>
                            {isAgeAbove18 &&
                                <li>                                    
                                    <Link to="/member/member-payment">Subscription Payment</Link>
                                </li>
                            }                           
                            <li><Link to="/member/change-password">Change Password</Link></li>
                            <li><a href="https://myemergencynetwork.com/Non%20Emergency%20Links.shtml" target="_blank">Non Emergency Links</a></li>
                            <li>
                                <button className="btntrans" onClick={handleLogout}>
                                    <i class="fa fa-sign-out" style={{ color: "#ea1b22" }} aria-hidden="true"></i> &nbsp;Sign Out
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    // loginStatus: (IsLogin) => dispatch(signin(IsLogin)),
    clearState: () => dispatch(reset())
})

const mapStateToProps = ({ login: { memberInfo } }) => ({
    memberInfo
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuMember));