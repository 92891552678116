import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Fetch } from "../../services/dataservice";
import { connect } from 'react-redux';
import { reset } from "../../redux/action/adminLogin.action";
import MenuAdmin from './MenuAdmin';

function AdminChangePassword({ history, adminInfo, clearState }) {
    const adminId = parseInt(adminInfo.map((item) => item.aId));

    const [credentials, setCredentials] = useState({
        adminID: adminId,
        password: null,
        newPassword: null,
        confNewPassword: null
    });

    const [passwordShown, setPasswordShown] = useState({
        pwd: false,
        newPwd: false,
        confPwd: false
    });
    const [iconClass, setIconClass] = useState({
        pwd: 'fa fa-eye-slash eyestyle',
        newPwd: 'fa fa-eye-slash eyestyle',
        confPwd: 'fa fa-eye-slash eyestyle'
    });    
    const togglePasswordVisiblity = (str) => {
        if (str === 'psd') {            
            if (!passwordShown.pwd) {                
                setPasswordShown({ ...passwordShown, pwd: true });
                setIconClass({ ...iconClass, pwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, pwd: false });
            setIconClass({ ...iconClass, pwd: 'fa fa-eye-slash eyestyle' });
        } else if (str === 'newpsd') {                    
            if (!passwordShown.newPwd) {                
                setPasswordShown({ ...passwordShown, newPwd: true });
                setIconClass({ ...iconClass, newPwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, newPwd: false });
            setIconClass({ ...iconClass, newPwd: 'fa fa-eye-slash eyestyle' });
        } else if (str === 'confpsd') {                    
            if (!passwordShown.confPwd) {                
                setPasswordShown({ ...passwordShown, confPwd: true });
                setIconClass({ ...iconClass, confPwd: 'fa fa-eye eyestyle' });
                return;
            }
            setPasswordShown({ ...passwordShown, confPwd: false });
            setIconClass({ ...iconClass, confPwd: 'fa fa-eye-slash eyestyle' });
        }                
    };

    const handleChange = (e, property) => {
        setCredentials({ ...credentials, [property]: e.target.value });
    };

    const handleChangePassword = async () => {
        try {
            if (credentials.newPassword !== credentials.confNewPassword) {
                alert('Password confirmation does not match.');
            } else {
                let res = await Fetch(`/AdminValidate/LoginById`, "post", credentials);
                if (res > 0) {
                    credentials.password = credentials.newPassword;
                    let result = await Fetch(`/AdminValidate/UpdatePassword`, "put", credentials);
                    if (result > 0) {
                        clearState();
                        history.push('/admin/admin-login');
                        alert('Updated successfully.');
                    } else {
                        alert('Error in updating password.');
                    }
                } else {
                    alert("Old password didn't match.");
                }
            }
        }
        catch (ex) {
            alert('Error...');
        }
    };

    return (
        <div>            
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuAdmin />
                            </div>
                            <div class="form-container">
                                <div class="form-group">
                                    <h3 style={{ color: "#fff", width: "100%", textAlign: "center" }}>Change Password</h3>
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>Old Password</label>                                    
                                    <input type={passwordShown.pwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "password")} />
                                    <i class={iconClass.pwd} onClick={() => togglePasswordVisiblity('psd')}></i> 
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label>New Password</label>                                    
                                    <input type={passwordShown.newPwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "newPassword")} />
                                    <i class={iconClass.newPwd} onClick={() => togglePasswordVisiblity('newpsd')}></i> 
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}> 
                                    <label>Confirm New Password</label>                                   
                                    <input type={passwordShown.confPwd ? 'text' : 'password'} class="form-control" onChange={(e) => handleChange(e, "confNewPassword")} />
                                    <i class={iconClass.confPwd} onClick={() => togglePasswordVisiblity('confpsd')}></i> 
                                </div>
                                <div class="form-group">
                                    <button className="btn btn-block btn-primary" onClick={handleChangePassword}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    clearState: () => dispatch(reset())
})

const mapStateToProps = ({ adminLogin: { adminInfo } }) => ({
    adminInfo
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminChangePassword));