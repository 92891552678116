import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import MenuMember from './MenuMember';
import CardDetails from './CardDetails';

const MemberCardInfo = ({ history }) => {
    const location = useLocation();
    const [showChild, setShowChild] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState({
        amount: 0,
        paymentDescriptor: null,
        termPlan: 0,
        gcid: 0,
        memberID: 0
    });

    useEffect(() => {
        if (location.state === undefined) {
            alert('An error occurred while processing your request. Please try again.');
            history.push('/member');
        } else {
            paymentRequest.amount = location.state.amount;
            paymentRequest.paymentDescriptor = location.state.paymentDescriptor;
            paymentRequest.termPlan = location.state.termPlan;
            paymentRequest.gcid = location.state.gcid;
            paymentRequest.memberID = location.state.memberID;
            setShowChild(true);
        }
    }, []);

    return (
        <div>
            <div class="int-container desktop">
                <img src="/assets/img/int-bg.jpg" alt="" />
            </div>
            <div class="int-container mobile">
                <img src="/assets/img/home-alert-4-mobile.jpg" alt="" />
            </div>
            <div class="main-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 text-center">
                        </div>
                        <div class="col-md-7">
                            <div class="profile-menu">
                                <MenuMember />
                            </div>
                            <div class="form-container" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '20px' }}>
                                {showChild &&
                                    <CardDetails currentPayment={paymentRequest} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MemberCardInfo);